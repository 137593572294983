<template>
  <div class="navigation-header-search">
    <!-- Focus management element -->
    <span
      ref="focusDummy"
      tabindex="-1"
      class="visually-hidden"
    ></span>

    <div
      class="navigation-header-search__input-wrapper"
      role="search"
      aria-label="Global search"
    >
      <svg-icon
        name="search-global"
        class="navigation-header-search__icon"
        aria-hidden="true"
      />

      <base-input
        ref="searchInput"
        v-model.trim="query"
        placeholder="Search"
        class="navigation-header-search__input"
        aria-label="Global search"
        aria-autocomplete="list"
        :aria-expanded="showResults"
        :aria-controls="showResults ? 'search-results-list' : undefined"
        :aria-activedescendant="selectedResult && resultIndex >= 0 ? `search-result-${resultIndex}` : undefined"
        @focus="showResults = true"
        @blur="handleBlur"
        @keydown="handleSearchKeydown"
      />

      <base-button
        v-if="query !== ''"
        class="navigation-header-search__clear"
        type="icon"
        icon="close"
        aria-label="Clear search"
        @click="clearSearch"
        @blur="handleBlur"
      >
        <span class="visually-hidden">Clear search</span>
      </base-button>

      <div
        v-else
        class="navigation-header-search__shortcut"
        aria-hidden="true"
      >
        <p>
          <span v-if="isMac">⌘</span>

          <span v-else>Ctrl + </span>
          K
        </p>
      </div>
    </div>

    <div
      v-show="showResults"
      id="search-results-list"
      ref="resultsWrapper"
      class="navigation-header-search__results-wrapper"
      role="listbox"
      aria-label="Search results"
    >
      <div class="navigation-header-search__results-wrapper-content">
        <!-- Loading State -->
        <div
          v-if="searchState.isLoading"
          class="navigation-header-search__result-item loading"
          role="status"
          aria-label="Loading results"
        >
          <div class="navigation-header-search__loading">
            <div class="navigation-header-search__loading-spinner"></div>

            <p class="navigation-header-search__loading-text">
              Searching...
            </p>
          </div>
        </div>

        <!-- No Results State -->
        <span
          v-else-if="shouldShowNoResults"
          class="navigation-header-search__result-item no-results"
          role="status"
        >
          <p class="navigation-header-search__result-item-name text-body-semibold">No results</p>

          <p class="navigation-header-search__result-item-description">
            Oops! We couldn't find what you're looking for. Try using our admin navigation in the sidebar or check our <a
              href="https://help.loopreturns.com/"
              class="navigation-header-search__link"
              target="_blank"
              rel="noopener noreferrer"
            >Help Center</a> for further assistance.</p>
        </span>

        <!-- Results -->
        <template v-else-if="filteredResults.length > 0">
          <!-- Returns Results Section -->
          <div
            v-if="searchState.returnsResults.length > 0"
            class="navigation-header-search__section"
          >
            <div class="navigation-header-search__section-header">
              Returns
            </div>

            <button
              v-for="(result, index) in filteredResults.filter(r => r.isReturn)"
              :id="`search-result-${index}`"
              :key="result.name"
              class="navigation-header-search__result-item"
              :class="{ 'is-active': selectedResult?.name === result.name }"
              role="option"
              :aria-selected="selectedResult?.name === result.name"
              @focus="showResults = true"
              @blur="handleBlur"
              @keydown="handleResultKeydown"
              @click="handleResultClick(result)"
            >
              <span class="navigation-header-search__result-item-name text-body-semibold">
                {{ result.name }}
              </span>

              <span class="navigation-header-search__result-item-description">
                {{ result.description }}
              </span>
            </button>
          </div>

          <!-- Navigation Results Section -->
          <div
            v-if="filteredResults.some(r => !r.isReturn)"
            class="navigation-header-search__section"
          >
            <div class="navigation-header-search__section-header">
              Navigation
            </div>

            <button
              v-for="(result, index) in filteredResults.filter(r => !r.isReturn)"
              :id="`search-result-${index}`"
              :key="result.name"
              class="navigation-header-search__result-item"
              :class="{ 'is-active': selectedResult?.name === result.name }"
              role="option"
              :aria-selected="selectedResult?.name === result.name"
              @focus="showResults = true"
              @blur="handleBlur"
              @keydown="handleResultKeydown"
              @click="handleResultClick(result)"
            >
              <span class="navigation-header-search__result-item-name text-body-semibold">
                {{ result.name }}
              </span>

              <span class="navigation-header-search__result-item-description">
                {{ result.description }}
              </span>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BaseButton } from '@loophq/ui';
import { BaseInput } from '@loophq/component-library';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { results } from '@/constants/globalHeaderSearchResults';
import router from '@/router';
import { useStore } from 'vuex';
import useSearchTracking from '@/util/composables/useSearchTracking';
import { debounce } from 'lodash';
import ReturnsSearchService from '@/util/api/returnsSearch';

// Constants
const BLUR_HANDLING_DELAY = 100;
const SEARCH_DEBOUNCE_DELAY = 300;
const MIN_SEARCH_LENGTH = 3;

// Core state
const store = useStore();
const query = ref('');
const showResults = ref(false);
const selectedResult = ref(null);
const resultIndex = ref(-1);

// Search state
const searchState = ref({
  debouncedTerm: '',
  returnsResults: [],
  isLoading: false,
  hasPendingSearch: false
});

// Component refs
const searchInput = ref(null);
const resultsWrapper = ref(null);
const focusDummy = ref(null);

// Initialize services
const returnsSearch = ReturnsSearchService();
const searchTracking = useSearchTracking({
  getUserData: () => store?.state?.userData || null
});

// Platform detection
const isMac = computed(() => {
  if (navigator?.userAgentData?.platform) {
    return navigator.userAgentData.platform.toLowerCase().includes('mac');
  }
  return navigator?.platform?.toLowerCase().includes('mac');
});

// Computed values
const trimmedQuery = computed(() => query.value.trim());

const filteredResults = computed(() => {
  if (!searchState.value.debouncedTerm) {
    return results
      .filter(item => item.isVisible && item.order === 1)
      .slice(0, 5);
  }

  const searchTerm = searchState.value.debouncedTerm.toLowerCase();
  const navigationResults = results
    .filter(item => (
      item.isVisible &&
      (item.name.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm))
    ))
    .sort((a, b) => a.order - b.order);

  const formattedReturnsResults = searchState.value.returnsResults.map(item => ({
    name: `Return #${item.returnId}`,
    description: `Order: ${item.orderName} - ${item.email}`,
    url: `/returns/${item.returnId}`,
    isVisible: true,
    order: 0,
    isReturn: true
  }));

  return [...formattedReturnsResults, ...navigationResults];
});

const shouldShowNoResults = computed(() => {
  const hasQuery = trimmedQuery.value !== '';
  const hasNoResults = filteredResults.value.length === 0;
  const isSearchingReturns = hasQuery && trimmedQuery.value.length >= MIN_SEARCH_LENGTH;
  const isSearchInProgress = searchState.value.isLoading || searchState.value.hasPendingSearch;

  return hasQuery && hasNoResults && (!isSearchingReturns || !isSearchInProgress);
});

// Search handling
const updateDebouncedSearchTerm = debounce((value) => {
  searchState.value.debouncedTerm = value;
  searchState.value.hasPendingSearch = false;
}, SEARCH_DEBOUNCE_DELAY);

const performSearch = async (term) => {
  if (term.length < MIN_SEARCH_LENGTH) {
    searchState.value = {
      ...searchState.value,
      returnsResults: [],
      isLoading: false,
      hasPendingSearch: false
    };
    return;
  }

  searchState.value.isLoading = true;
  searchState.value.hasPendingSearch = true;

  try {
    searchState.value.returnsResults = await returnsSearch.searchReturns(term);
  } catch (error) {
    console.error('Error searching returns:', error);
    searchState.value.returnsResults = [];
  } finally {
    if (term === searchState.value.debouncedTerm) {
      searchState.value.isLoading = false;
      searchState.value.hasPendingSearch = false;
    }
  }
};

// Event handlers
const handleSearchKeydown = (e) => {
  if (e.key === 'Escape') {
    showResults.value = false;
    return;
  }

  const keyActions = {
    ArrowDown: () => selectResult('next'),
    ArrowUp: () => selectResult('previous'),
    Enter: () => {
      e.preventDefault();
      showResults.value = false;
      focusDummy.value?.focus();

      if (selectedResult.value) {
        router.push(selectedResult.value.url);
      } else if (trimmedQuery.value) {
        handleExplicitSearchSubmit();
      }
    }
  };

  const action = keyActions[e.key];
  if (action) {
    e.preventDefault();
    action();
  }
};

const handleResultClick = (result) => {
  if (!trimmedQuery.value) {
    router.push(result.url);
    showResults.value = false;
    searchInput.value?.$el.querySelector('input')?.blur();
    return;
  }

  try {
    searchTracking.handleResultClick({
      query: trimmedQuery.value,
      page: result.url,
      getSearchResults: getSearchResultsData
    });
  } catch (error) {
    console.error('Error tracking search result click:', error);
  } finally {
    router.push(result.url);
    showResults.value = false;
    searchInput.value?.$el.querySelector('input')?.blur();
  }
};

const handleBlur = () => {
  setTimeout(() => {
    const activeElement = document?.activeElement;
    if (!activeElement?.closest('.navigation-header-search')) {
      showResults.value = false;
    }
  }, BLUR_HANDLING_DELAY);
};

const clearSearch = () => {
  if (trimmedQuery.value) {
    searchTracking.handleSearchClear({
      query: trimmedQuery.value,
      getSearchResults: getSearchResultsData
    });
  }
  query.value = '';
};

// Navigation helpers
const selectResult = (direction) => {
  if (!filteredResults.value?.length) {
    resultIndex.value = -1;
    selectedResult.value = null;
    return;
  }

  const totalResults = filteredResults.value.length;
  const newIndex = direction === 'next'
    ? (resultIndex.value + 1) % totalResults
    : (resultIndex.value - 1 + totalResults) % totalResults;

  resultIndex.value = newIndex;
  selectedResult.value = filteredResults.value[newIndex];

  const resultElements = resultsWrapper.value?.querySelectorAll('.navigation-header-search__result-item');
  resultElements?.[newIndex]?.scrollIntoView?.({ block: 'nearest', behavior: 'smooth' });
};

// Search tracking helpers
const getSearchResultsData = () => ({
  hasResults: filteredResults.value?.length > 0,
  count: filteredResults.value?.length || 0,
  names: filteredResults.value?.map(result => result.name) || []
});

const handleExplicitSearchSubmit = () => {
  if (!trimmedQuery.value) return;

  searchTracking.handleSearchSubmit({
    query: trimmedQuery.value,
    getSearchResults: getSearchResultsData
  });
};

// Watchers
watch(query, (newQuery, oldQuery) => {
  if (newQuery === oldQuery) return;

  resultIndex.value = -1;
  selectedResult.value = null;

  if (trimmedQuery.value) {
    showResults.value = true;

    if (trimmedQuery.value.length >= MIN_SEARCH_LENGTH) {
      searchState.value.isLoading = true;
      searchState.value.hasPendingSearch = true;
    }

    updateDebouncedSearchTerm(trimmedQuery.value);
    searchTracking.startTypingSession({
      query: trimmedQuery.value,
      getSearchResults: getSearchResultsData
    });
  } else {
    searchState.value = {
      debouncedTerm: '',
      returnsResults: [],
      isLoading: false,
      hasPendingSearch: false
    };
  }
});

watch(() => searchState.value.debouncedTerm, performSearch);

// Watch for no results state to track it
watch(shouldShowNoResults, (hasNoResults) => {
  if (hasNoResults && !searchTracking.isTyping.value) {
    searchTracking.trackNoResults({
      query: trimmedQuery.value
    });
  }
});

// Lifecycle
onMounted(() => {
  window?.addEventListener('keydown', (e) => {
    if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      if (searchInput.value) {
        const inputElement = searchInput.value.$el?.querySelector('input') || searchInput.value.$el;

        if (inputElement) {
          inputElement.focus();
        }
      }
    }
  });
});

onUnmounted(() => {
  updateDebouncedSearchTerm.cancel?.();
  searchTracking.cleanup();

  // Clear refs
  searchInput.value = null;
  resultsWrapper.value = null;
  selectedResult.value = null;
  query.value = '';
  searchState.value = {
    debouncedTerm: '',
    returnsResults: [],
    isLoading: false,
    hasPendingSearch: false
  };
});
</script>

<style lang="scss" scoped>
$block: '.navigation-header-search';

#{$block} {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  margin: 0 var(--spacing-8);

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  &__input-wrapper {
    position: relative;
    width: 100%;
    max-width: 580px;
  }

  &__icon {
    position: absolute;
    left: var(--spacing-12);
    top: var(--spacing-8);
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  &__shortcut {
    visibility: hidden;
    position: absolute;
    right: var(--spacing-16);
    top: var(--spacing-8);
    color: var(--color-neutral-100);
    background: var(--color-neutral-900);
    padding: 2px var(--spacing-4);
    border-radius: var(--spacing-4);
    border: 1px solid var(--color-neutral-500);

    @media screen and (width >= 960px) {
      visibility: visible;
    }

    p {
      font-size: 0.75rem;
    }
  }

  &__clear {
    position: absolute;
    right: var(--spacing-12);
    top: var(--spacing-8);
    color: var(--color-neutral-100) !important;
    z-index: 1;
  }

  &__input {
    width: 100%;
    padding-left: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--color-neutral-700) !important;
    background: var(--color-neutral-900) !important;
    color: var(--color-neutral-100) !important;
  }

  &__results-wrapper {
    position: absolute;
    top: calc(100% + 12px);
    width: 100%;
    max-width: 580px;
    border-radius: 10px !important;
    background: var(--color-neutral-900);
    border: 1px solid var(--color-neutral-700);
    max-height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &__results-wrapper-content {
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    padding: var(--spacing-16);
  }

  &__result-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-12) var(--spacing-16);
    text-align: left;
    border-radius: var(--spacing-4);

    &:hover:not(.loading),
    &.is-active:not(.loading) {
      background: var(--color-neutral-700);
    }

    &.focus-visible {
      outline: auto;
    }

    &.no-results {
      justify-content: center;
      text-align: center;
      margin: 0;
      padding: var(--spacing-24);

      .navigation-header-search__result-item-name {
        margin-bottom: var(--spacing-8);
      }

      .navigation-header-search__result-item-description {
        font-size: 0.875rem;
      }

      &:hover,
      &.is-active {
        background: transparent;
      }
    }

    &.loading {
      padding: var(--spacing-24);
      cursor: default;
    }
  }

  &__result-item-name {
    font-size: 1rem;
    color: var(--color-neutral-100);
  }

  &__result-item-description {
    font-size: 0.75rem;
    color: var(--color-neutral-100);
  }

  &__link {
    color: var(--color-neutral-100);
    text-decoration: underline;
  }

  &__section {
    margin-bottom: var(--spacing-16);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-header {
    padding: var(--spacing-8) var(--spacing-16);
    color: var(--color-neutral-300);
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-8);
  }

  &__loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid var(--color-neutral-700);
    border-top-color: var(--color-neutral-100);
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }

  &__loading-text {
    color: var(--color-neutral-100);
    font-size: 0.875rem;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
