export const chiliPiperBookingLink = {
  ROUTER_LINK: 'global-msm-expansion-router',
  DOMAIN: 'loopreturns',
  TRIGGER: 'InAppButton'
};

export const chiliPiperBookingSource = {
  POS: 'Loop Point of Sale',
  SHOP_NOW: 'Loop Shop Now',
  OFFSET: 'Loop Offset',
};
