<template>
  <div
    v-if="message.content?.error"
    class="chat-message__bubble --error"
  >
    <base-text
      v-html="message.content.error"
    />
  </div>

  <component
    :is="resolvedComponent"
    v-else-if="resolvedComponent"
    :result="message.content"
    @message="handleMessage"
  />
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue';
import {
  BaseText,
} from '@loophq/ui';

const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['message']);

// Map of tool names to their component paths
const toolComponents = {
  'show_return_details': defineAsyncComponent(() => import('./ShowReturnDetailsTool.vue')),
  'create_workflow': defineAsyncComponent(() => import('./CreateWorkflowTool.vue')),
};

// Resolve the component based on the tool name
const resolvedComponent = computed(() => {
  const toolName = props.message.name;

  return toolComponents[toolName] || null;
});

// Handle messages from tool components
const handleMessage = (message) => {
  emit('message', message);
};
</script>
