export const actions = {
  BLANK_ACTION: 'BlankAction',
  EXCLUDE_OUTCOME: 'ExcludeOutcome',
  RETURN_WINDOW: 'ReturnWindow',
  HANDLING_FEES: 'HandlingFee',
  PROCESSING_EVENT: 'ProcessingEvent',
  BONUS_CREDIT: 'BonusCredit',
  FLAG_RETURN: 'FlagReturn',
  DISABLE_LABEL: 'DisableLabel',
  EXCLUDE_RETURN_METHODS: 'ExcludeReturnMethod',
  STORE_CREDIT_INCENTIVE: 'StoreCreditIncentive',
  KEEP_DONATE: 'KeepDonate',
  REVIEW_REJECT: 'ReviewReject',
  LINE_ITEM_FEES: 'LineItemFees',
  SHOP_NOW_DISCOUNT: 'ShopNowDiscount',
  REASON_GROUP_OVERRIDE: 'ReasonGroupOverride',
  DESTINATION_OVERRIDE: 'DestinationOverride',
};

export const ruleTypes = {
  CONDITION: 'Condition',
  USER_INPUT: 'UserInput',
  ACTION: 'Action',
  ...actions
};

export const moduleTypes = {
  CONDITION: 'condition',
  ACTION: 'action',
  USER_INPUT: 'userInput',
};

const outcomeIcon = {
  name: 'outcome',
  backgroundColor: '#2B33FF',
  color: '#FFFFFF'
};

export const ruleIcons = {
  [ruleTypes.CONDITION]: {
    name: 'condition',
    backgroundColor: '#EDC200',
    color: '#FFFFFF'
  },
  [ruleTypes.USER_INPUT]: {
    name: 'question',
    backgroundColor: '#DE3618',
    color: '#FFFFFF'
  },
  [ruleTypes.ACTION]: outcomeIcon,
};

export const conditions = {
  PRODUCT_TYPE: 'lineItem.product_type',
  PRODUCT_VENDOR: 'lineItem.vendor',
  PRODUCT_ID: 'lineItem.product_id',
  PRODUCT_SKU: 'lineItem.sku',
  PRODUCT_TAGS: 'lineItem.tags',
  PRODUCT_TAGS_PURCHASE: 'lineItem.tags_purchase',
  CUSTOMER_COHORT: 'customer.cohort',
};

export const conditionLabels = {
  [conditions.PRODUCT_TYPE]: 'Product type',
  [conditions.PRODUCT_VENDOR]: 'Product vendor',
  [conditions.PRODUCT_ID]: 'Product ID',
  [conditions.PRODUCT_SKU]: 'Product SKU',
  [conditions.PRODUCT_TAGS]: 'Product tags',
  [conditions.CUSTOMER_COHORT]: 'Cohort',
};

export const outcomes = {
  KEEP_ITEM: 'keep',
  REJECT: 'reject',
  REVIEW: 'review',
  DONATE: 'donate',
};

export const outcomeLabels = {
  [outcomes.KEEP_ITEM]: 'Keep item',
  [outcomes.REJECT]: 'Reject item',
  [outcomes.REVIEW]: 'Manual review',
  [outcomes.DONATE]: 'Donate',
};

export const dateOperators = {
  BETWEEN_DATE: 'betweenDate',
  AFTER_DATE: 'afterDate',
  BEFORE_DATE: 'beforeDate',
};

export const rangeOperators = {
  LESS_THAN: '<',
  GREATER_THAN: '>',
  BETWEEN: '<='
};

export const miscOperators = {
  MISSING: 'missing',
};

export const rangeOperatorLabels = {
  [rangeOperators.GREATER_THAN]: 'Is greater than',
  [rangeOperators.LESS_THAN]: 'Is less than',
  [rangeOperators.BETWEEN]: 'Is between',
};

export const workflowBuilderRangeOperators = {
  LESS_THAN: 'lessThan',
  GREATER_THAN: 'greaterThan',
  BETWEEN: 'between',
};

export const operators = {
  ...dateOperators,
  ...rangeOperators
};

export const conditionActionOperators = {
  AND: 'and',
  OR: 'or',
};
