import axios from 'axios';
import changeCase from 'change-object-case';
import store from '@/store';

changeCase.options = { recursive: true, arrayRecursive: true };

const reporting = {
  /**
   *
   * @param {object} options
   * @param {number} options.limit - Limit the amount of reports to pull. Default 250
   * @param {number} options.page - Page to pull. Default 1
   * @param {string} options.direction - Sort, ASC or DSC. Default ASC.
   */
  getAll(options = {}) {
    return axios
      .get(`/v2/reports`, { params: options })
      .then(res => {
        return Object.values(changeCase.toCamel(res.data));
      });
  },

  /**
   *
   * @param {string} slug - The report slug.
   */
  getEmbedUrlBySlug(slug) {
    try {
      if (!slug) {
        console.error(`API: reporting.getEmbedUrlBySlug() requires a slug: shop id - ${store.getters.shop.id}.`);
        return Promise.reject();
      }
      return axios
        .get(`/v2/reports/embed/${slug}`)
        .then(res => res.data);
    } catch (error) {
      console.error(`API: reporting.getEmbedUrlBySlug() error: slug - ${slug}, shop id - ${store.getters.shop.id}`, error);
    }
  },

  getAdvancedInsights(params = {}) {
    if (!params.report) {
      console.error('API: reporting.getAdvancedInsights() requires a report param');
      return Promise.reject();
    }

    return axios
      .get(`reporting/advanced-insights`, { params })
      .then((res) => res.data);
  },

  async getDashboards({ type, id }) {
    let unInterceptedAxios = axios.create();

    const token = await store.dispatch('analytics/getToken');
    const payload = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Metadata-Tag': 'custom-call'
      }
    };

    return unInterceptedAxios
      .get(
        `https://loopreturns.cloud.gooddata.com/api/v1/entities/workspaces/${id}/analyticalDashboards?metaInclude=accessInfo&include=analyticalDashboards&origin=NATIVE`,
        payload,
      )
      .then((response) => response.data)
      .then((data) => {
        let reports = data.data.map((item) => {
          return {
            id: item.id,
            title: item.attributes.title,
            type: item.meta.origin.originType,
            private: item.meta.accessInfo.private,
          };
        });
        let privateReports = reports.filter((item) => item.private);
        let sharedReports = reports.filter((item) => !item.private);
        return type === 'private' ? privateReports : sharedReports;
      });
  },

  provisionUser() {
    return axios
      .get(`reporting/provision-user`)
      .then((res) => res.data);
  },

  getToken() {
    return axios
      .get(`reporting/token`)
      .then((res) => res.data);
  }
};

export default reporting;
