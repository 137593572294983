import axios from 'axios';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };

/**
 * Converts an axios result to camcel cased data
 * @param {Promise} request - an axios request
 * @returns {Promise}
 */
const resultToCamelCase = (request) => {
  return request.then((res) => changeCase.toCamel(res.data));
};

const integrations = {
  getAll() {
    return resultToCamelCase(axios.get('/shipping/integrations'));
  },
  getEnabled() {
    return resultToCamelCase(axios.get('/shipping/integrations/connected'));
  },
  connect(id, config) {
    return resultToCamelCase(axios.post(`/shipping/integrations/${id}/connect`, changeCase.toParam(config)));
  },
  deleteConnection(id) {
    return resultToCamelCase(axios.delete(`/shipping/integrations/${id}/connect`));
  },
  update(id, config) {
    return resultToCamelCase(axios.post(`/shipping/integrations/${id}/update`, changeCase.toParam(config)));
  },
  setDefault(id) {
    return resultToCamelCase(axios.post(`/shipping/integrations/${id}/default`));
  },
  refreshCarriers(id) {
    return resultToCamelCase(axios.post(`/shipping/integrations/${id}/refresh`), {});
  }
};

const carriers = {
  getAllCarriers() {
    return resultToCamelCase(axios.get(`/shipping/carriers/`));
  },
  getAll(integrationId) {
    return resultToCamelCase(axios.get(`/shipping/integrations/${integrationId}/carriers`));
  },
  getActive(integrationId) {
    return resultToCamelCase(axios.get(`/shipping/integrations/${integrationId}/carriers/active`));
  },
  getEnabled(integrationId) {
    return resultToCamelCase(axios.get(`/shipping/integrations/${integrationId}/carriers/enabled`));
  },
  enable(integrationId, carrierId) {
    return resultToCamelCase(axios.post(`/shipping/integrations/${integrationId}/carriers`, { carrier_ids: [carrierId] }));
  },
  disable(integrationId, carrierId) {
    return resultToCamelCase(axios.delete(`/shipping/integrations/${integrationId}/carriers`, { data: { carrier_ids: [carrierId] } }));
  },
  async validate(integrationSlug, configuration) {
    return await axios.post(`/shipping/integrations/${integrationSlug}/validate`, { configuration });
  },
  async toggle(integrationId, changeSet) {
    if (integrationId === undefined || !Array.isArray(changeSet) || !changeSet?.length) {
      return Promise.reject('ShippingServices.carriers.toggle: Bailing due to missing integrationId or no changeSet');
    }

    const endpoint = `/shipping/integrations/${integrationId}/carriers`;
    const toEnable = changeSet.filter((change) => change.enabled);
    const toDisable = changeSet.filter((change) => !change.enabled);
    let res = [];

    if (toEnable.length) {
      res = await axios.post(endpoint, {
        carrier_ids: toEnable.map((change) => change.id)
      });
    }

    if (toDisable.length) {
      res = await axios.delete(endpoint, {
        data: {
          carrier_ids: toDisable.map((change) => change.id)
        }
      });
    }

    return changeCase.toCamel(res.data);
  },
  update(integrationId, changeSet) {
    if (integrationId === undefined || !Array.isArray(changeSet) || !changeSet?.length) {
      return Promise.reject('ShippingServices.carriers.update: Bailing due to missing integrationId or no changeSet');
    }

    return Promise.all(changeSet.map((change) => {
      const endpoint = `/shipping/integrations/${integrationId}/carriers/${change.id}/service_types`;
      const ids = change.toEnable.map((item) => item.id);
      return axios
        .post(endpoint, {
          service_type_ids: ids,
          ...('preferred' in change ? { preferred: change.preferred } : {}),
          ...('shipperAddress' in change ? { shipperAddress: change.shipperAddress } : {}),
        })
        .then((res) => {
          return {
            id: change.id,
            service_types: res.data,
            preferred: change.preferred,
            shipperAddress: change.shipperAddress,
          };
        });
    }))
      .then((res) => {
        return {
          carriers: res,
          serviceTypes: res.reduce((acc, cur) => {
            return [
              ...acc,
              ...changeCase.toCamel(cur.service_types)
            ];
          }, [])
        };
      });
  }
};

const returnPolicyCarriers = {
  getPolicyCarriers(policyId = '', shippingSettingId) {
    return resultToCamelCase(axios.get(`/shipping/return-policy-carriers/${policyId}`, { params: { shipping_setting_id: shippingSettingId } }));
  },
  updatePolicyCarriers(policyId, carriers) {
    return resultToCamelCase(axios.put(`/shipping/return-policy-carriers/${policyId}`, changeCase.snakeKeys(carriers)));
  },
};

const serviceTypes = {
  getAll(integrationId, carrierId) {
    return resultToCamelCase(axios.get(`/shipping/integrations/${integrationId}/carriers/${carrierId}/service_types`));
  },
  getActive(integrationId, carrierId) {
    return resultToCamelCase(axios.get(`/shipping/integrations/${integrationId}/carriers/${carrierId}/service_types/active`));
  },
  getEnabled(integrationId, carrierId) {
    return resultToCamelCase(axios.get(`/shipping/integrations/${integrationId}/carriers/${carrierId}/service_types/enabled`));
  },
  enable(integrationId, carrierId, serviceTypeArray) {
    return resultToCamelCase(axios.post(`/shipping/integrations/${integrationId}/carriers/${carrierId}/service_types`, { service_type_ids: serviceTypeArray }));
  },
  disable(integrationId, carrierId, serviceTypeArray) {
    return resultToCamelCase(axios.delete(`/shipping/integrations/${integrationId}/carriers/${carrierId}/service_types`, serviceTypeArray));
  },
  update(integrationId, changeSet) {
    if (integrationId === undefined || !Array.isArray(changeSet) || !changeSet?.length) {
      return Promise.reject('ShippingServices.serviceTypes.update: Bailing due to missing integrationId or no changeSet');
    }

    return Promise.all(changeSet.map((change) => {
      const endpoint = `/shipping/integrations/${integrationId}/carriers/${change.id}/service_types`;
      const ids = change.toEnable.map((item) => item.id);
      return axios.post(endpoint, { service_type_ids: ids });
    }))
      .then((res) => {
        return res.reduce((acc, cur) => {
          return [
            ...acc,
            ...changeCase.toCamel(cur.data)
          ];
        }, []);
      });
  }
};

const settings = {
  get() {
    return resultToCamelCase(axios.get(`/shipping`));
  }
};

const easypostShipmentOptions = {
  get(carrierId, integrationSlug) {
    return resultToCamelCase(axios.get(`/shipping/${integrationSlug}/carriers/${carrierId}/shipment-options`));
  },
  createOrUpdate(carrierId, shipmentOptions, integrationSlug) {
    return axios.put(`/shipping/${integrationSlug}/carriers/${carrierId}/shipment-options`, {
      options: [...shipmentOptions]
    });
  }
};

const easypostDestinationSupport = {
  getAddresses() {
    return resultToCamelCase(axios.get('/shipping/easy-post/test-carrier-support/addresses'));
  },
  validateShippingSupport(payload) {
    return resultToCamelCase(axios.post('/shipping/easy-post/test-carrier-support', payload));
  }
};

export default {
  integrations,
  carriers,
  returnPolicyCarriers,
  serviceTypes,
  settings,
  easypostShipmentOptions,
  easypostDestinationSupport
};
