import axios from 'axios';

const assistant = {
  async chat({ session_id, messages, message, context }) {
    return axios.post('assistant/chat', {
      session_id,
      messages,
      message,
      ...(context && { context }),
    }).then(res => res.data);
  },

  async submitFeedback({ session_id, rating, feedback }) {
    return axios.post('assistant/feedback', {
      session_id,
      rating,
      ...(feedback && { feedback }),
    }).then(res => res.data);
  },
};

export default assistant;
