import Reports from '@/util/api/reporting';

export default {
  namespaced: true,
  state: {
    token: null
  },
  mutations: {
    setToken(state, data) {
      state.token = data;
    }
  },
  actions: {
    async getToken({ state, commit }, refetch = false) {
      // Since this data doesn't change often, we want to just grab this from
      // memory if we've already requested it this session
      if (state.token && !refetch) {
        return state.token;
      }

      const { token } = await Reports.getToken();
      commit('setToken', token);

      return token;
    }
  },
};
