import { chiliPiperBookingLink } from '@/constants/chiliPiperBooking';

let isChiliPiperLoaded = false;

export const initChiliPiper = () => new Promise((resolve, reject) => {
  if (isChiliPiperLoaded) {
    resolve();
    return;
  }

  const scriptId = 'chiliPiperScript';
  if (document.getElementById(scriptId)) {
    isChiliPiperLoaded = true;
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.src = 'https://loopreturns.chilipiper.com/concierge-js/cjs/concierge.js';
  script.id = scriptId;
  script.type = 'text/javascript';
  script.onload = () => {
    isChiliPiperLoaded = true;
    resolve();
  };

  script.onerror = () => {
    reject(new Error('Failed to load ChiliPiper script'));
  };

  document.head.appendChild(script);
});

export const submitChiliPiperInquiry = (router, lead) => {
  if (!isChiliPiperLoaded) {
    console.warn('ChiliPiper script is not loaded yet.');
    return;
  }

  window.ChiliPiper.submit(
    chiliPiperBookingLink.DOMAIN,
    router,
    { trigger: chiliPiperBookingLink.TRIGGER,
      lead: lead ? lead : {},
    });
};
