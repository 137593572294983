import { conditionActionOperators, rangeOperators, ruleTypes } from '@/util/schemas/workflow-rules';
import { createId } from '@/util/helpers/ids';
import { totalConditions, conditionDescriptions } from '@/pages/Workflows/WorkflowsDetail/conditions';
import { getDisplayForRule } from './format';

/**
 * Converts a JSONLogic object to the structure we use to generate the workflows page
 * @param {object} json
 * @returns {WorkflowRule[]}
 */
export const buildRulesFromJSON = (json) => {
  const rules = {};
  if (!json) {
    return null;
  }

  // Check for validation wrapper
  if (!json?.if[1] && json?.if[2]) {
    json = json.if[2];
  }

  buildRule(json, rules, 0, { trigger: true, id: 1 });
  const rulesArray = Object.values(rules).sort((a, b) => a.id > b.id);
  // If there are only 2 steps add a connector item for the ui
  if (rulesArray.length === 2) {
    rulesArray.splice(1, 0, buildConnector());
  }
  return addUserInputId(rulesArray);
};

/**
 * Converts rules array to a stringified JSONLogic object
 * @param {object} rules
 * @returns {?string}
 */
export const buildJSONFromRules = (rules) => {
  if (!rules) {
    return null;
  }
  rules = rules.filter(rule => !rule.ignore);
  const trigger = rules.find(rule => rule.trigger);
  const json = buildJSONLogicItemFromRule(trigger, rules);
  const workflow = addValidation(rules, json);
  return JSON.stringify(workflow);
};

export const getDescriptionForRule = (field) => {
  const key = Object.keys(conditionDescriptions).find(n => field.includes(n));
  return conditionDescriptions[key];
};

const buildJSONLogicItemFromRule = (rule, rules) => {
  if (!rule) {
    return null;
  }
  if (rule.type === ruleTypes.CONDITION) {
    return rule.trigger ? buildIfTriggerStatement(rule, rules) : buildIfStatement(rule, rules);
  }

  if (rule.type === ruleTypes.ACTION) {
    return buildAction(rule);
  }

  return buildReturnType(rule);
};

const buildIfStatement = (rule, rules) => {
  const successStep = rules.find(item => rule.success === item.id);
  const failStep = rules.find(item => rule.fail === item.id);
  return {
    'if': [
      {
        [rule.operator]: getIfCondition(rule)
      },
      buildJSONLogicItemFromRule(successStep, rules),
      buildJSONLogicItemFromRule(failStep, rules)
    ]
  };
};

const buildIfTriggerStatement = (rule, rules) => {
  const successStep = rules.find(item => rule.success === item.id);
  const failStep = rules.find(item => rule.fail === item.id);
  const conditions = rule.conditions.map(item => {
    return {
      [item.operator]: getIfCondition(item)
    };
  });
  return {
    'if': [
      {
        [rule.operator]: conditions,
      },
      buildJSONLogicItemFromRule(successStep, rules),
      buildJSONLogicItemFromRule(failStep, rules)
    ]
  };
};

const getIfCondition = (rule) => {
  if (Object.values(rangeOperators).includes(rule.operator)) {
    return buildRangeOperatorCondition(rule);
  }

  if (rule.operator !== 'missing') {
    return buildMissingOperatorCondition(rule);
  }

  return buildGenericCondition(rule);
};

const buildGenericCondition = (rule) => {
  return [
    rule.field
  ];
};

const buildMissingOperatorCondition = (rule) => {
  return [
    {
      'var': rule.field
    },
    rule.value,
  ];
};

const buildRangeOperatorCondition = (rule) => {
  const min = totalConditions.includes(rule.field) ? rule.value.min * 100 : rule.value.min;
  if (rule.operator === rangeOperators.BETWEEN) {
    const max = totalConditions.includes(rule.field) ? rule.value.max * 100 : rule.value.max;
    return [
      min,
      {
        'var': rule.field
      },
      max,
    ];
  }

  return [
    {
      'var': rule.field,
    },
    min
  ];
};

const buildAction = (rule) => {
  return rule.actions.map(action => {
    return buildReturnType(action);
  }).filter(n => n);
};

const buildReturnType = (rule) => {
  const isEmpty = Object.keys(rule).length === 0;
  if (isEmpty) return;

  // eslint-disable-next-line no-unused-vars
  const { id, value, ...item } = rule;
  return {
    ...item,
    value: JSON.stringify(value),
  };
};

/**
 * 
 * @param {WorkflowBuilderAction[]|object} json 
 * @param {*} rules 
 * @param {int} id 
 * @param {object} opts 
 * @returns 
 */
const buildRule = (json, rules = {}, id, opts) => {
  let rule;
  if (json.if) {
    rule = opts?.trigger ? buildTriggerCondition(json.if, rules, id, opts) : buildCondition(json.if, rules, id, opts);
    rules[rule.id] = rule;
  }

  if (json.length && json.find(item => item.type)) {
    rule = {
      actions: json.map(item => buildType(item, id)),
      type: ruleTypes.ACTION,
      id,
    };
    rules[rule.id] = rule;
  } else if (json.type === ruleTypes.USER_INPUT) {
    rule = buildType(json, id);
    rules[rule.id] = rule;
  } else if (json.type) {
    rule = {
      actions: [buildType(json, id)],
      type: ruleTypes.ACTION,
      id,
    };
    rules[rule.id] = rule;
  }
  return rule?.id;
};

const getFieldAndValueBasedOnOperator = (operator, criteria) => {
  if (operator === rangeOperators.BETWEEN) {
    const [min, field, max] = criteria[operator];
    const value = {
      min: parseFloat(totalConditions.includes(field?.var) ? min / 100 : min),
      max: parseFloat(totalConditions.includes(field?.var) ? max / 100 : max),
    };
    return [field, value];
  }

  if (operator === rangeOperators.LESS_THAN || operator === rangeOperators.GREATER_THAN) {
    const [field, values] = criteria[operator];
    const value = {
      min: parseFloat(totalConditions.includes(field?.var) ? values / 100 : values),
      max: 0
    };
    return [field, value];
  }

  const [field, value] = criteria[operator];
  return [field, value];
};

const buildCondition = (condition, rules, id, opts) => {
  const [criteria, success, fail] = condition;
  const operator = Object.keys(criteria)[0];
  const [field, value] = getFieldAndValueBasedOnOperator(operator, criteria);
  const successLabel = 'True';
  const failLabel = 'Yes';

  /** @type {WorkflowRule} */
  return {
    id,
    type: ruleTypes.CONDITION,
    operator,
    field: field?.var ?? field,
    value,
    label: opts?.label ?? null,
    trigger: false,
    success: success ? buildRule(success, rules, id + 1, { label: successLabel, trigger: false }) : null,
    fail: fail ? buildRule(fail, rules, id + 2, { label: failLabel, trigger: false }) : null,
    display: getDisplayForRule(operator, value, field?.var ?? field)
  };
};

const buildTriggerCondition = (json, rules, id, opts) => {
  let conditionList;
  const successLabel = 'True';
  const failLabel = 'Yes';
  const [criteria, success, fail] = json;
  let operator = Object.keys(criteria)[0];
  if (operator === conditionActionOperators.AND || operator === conditionActionOperators.OR) {
    const conditions = criteria[operator];
    conditionList = conditions.map(item => {
      const conditionOperator = Object.keys(item)[0];
      return buildConditionItem(conditionOperator, item);
    });
  } else {
    conditionList = [buildConditionItem(operator, criteria)];
    operator = conditionActionOperators.AND;
  }

  /** @type {WorkflowRule} */
  return {
    id,
    type: ruleTypes.CONDITION,
    conditions: conditionList,
    operator,
    label: opts?.label ?? null,
    trigger: true,
    success: success ? buildRule(success, rules, id + 1, { label: successLabel, trigger: false }) : null,
    fail: fail ? buildRule(fail, rules, id + 2, { label: failLabel, trigger: false }) : null,
  };
};

const buildConditionItem = (operator, criteria) => {
  const [field, value] = getFieldAndValueBasedOnOperator(operator, criteria);
  return {
    field: field?.var ?? field,
    value,
    operator,
    display: getDisplayForRule(operator, value, field?.var ?? field),
  };
};

const buildType = (obj, id) => {
  if (!obj) return;

  const value = JSON.parse(obj.value);

  /** @type {WorkflowRule} */
  return {
    ...obj,
    value,
    id
  };
};

const buildConnector = () => {
  return {
    type: ruleTypes.CONDITION,
    label: 'True',
    ignore: true,
    trigger: false,
  };
};

/**
 * Generates unique ids for user input logic if needed
 * @param {WorkflowRule[]} rules
 * @returns {WorkflowRule[]}
 */
export const addUserInputId = (rules) => {
  // Find the user input rule in this set
  const userInput = rules.find((rule) => rule.type === ruleTypes.USER_INPUT);

  // If it doesn't exist we don't need to do anything further
  if (!userInput) {
    return rules;
  }

  // Find the rule that triggers the user input
  // The failure path on the trigger is used to re-evaluate the workflow, so it can proceed
  const { fail: evaluatorId, id: triggerId } = rules.find((rule) => rule.success === userInput.id);

  const id = `lineItem.user_input_${createId()}`;

  return rules.map((rule) => {
    // The rules that trigger and evaluate the user input are conditions, so we need to
    // set the field that they're conditional on to the user input key we're going to track
    if ([triggerId, evaluatorId].includes(rule.id)) {
      return {
        ...rule,
        field: id,
        // Some merchants have gotten to a state where their workflow saved with a blank user input operator
        // This will default the operator to the correct value when re-saving the workflow
        operator: !rule.operator.length ? '==' : rule.operator,
      };
    }

    // The user input rule itself needs the id as a part of its value.
    // It will use that id to set the user input data before the workflow gets re-evaluated
    if (rule.id === userInput.id) {
      return {
        ...rule,
        value: {
          ...rule.value,
          id
        }
      };
    }

    return rule;
  });
};

// Wraps the workflow in a missing if statement with each condition field to provide basic validation of the data passed in
const addValidation = (rules, json) => {
  const condition = rules.find(rule => rule.type === ruleTypes.CONDITION && rule.trigger);
  const conditionFields = condition.conditions.map(condition => condition.field);

  return {
    'if': [
      {
        'missing': conditionFields
      },
      null,
      json
    ]
  };
};
