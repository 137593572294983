<template>
  <div
    class="multi-product-layout"
    :class="{ 'hourglass': !userData, 'has-assistant-sidebar': showAssistantSidebar && !isAssistantSidebarCollapsed }"
  >
    <template v-if="!userData">
      <loading-page :hourglass="true" />
    </template>

    <div
      v-else
      class="multi-product-layout__wrapper"
    >
      <navigation-header
        ref="header"
        :userData="userData"
        class="multi-product-layout__header"
        @menu-click="toggleSidebar"
        @update-size="headerSizeHandler"
      />

      <multi-product-nav-sidebar
        ref="sidebar"
        :userData="userData"
        class="multi-product-layout__sidebar"
        :class="{ 'is-visible': isSidebarVisible }"
        @toggle-menu="toggleStyles"
        @update-size="sidebarSizeHandler"
      />

      <main
        v-if="type ==='full'"
        class="multi-product-layout__content full"
        :class="{ 'is-expanded': isExpanded, 'with-assistant-sidebar': showAssistantSidebar }"
      >
        <div class="multi-product-layout__inner">
          <div
            class="multi-product-layout__inner-scroll"
            :style="{ height: computedHeight }"
          >
            <global-banner
              v-if="userData"
              :user-data="userData"
              :type="type"
            />

            <slot>
              Page not found.
            </slot>
          </div>
        </div>
      </main>

      <main
        v-else-if="type ==='wide' || type ==='normal'"
        class="multi-product-layout__content full"
        :class="{ 'is-expanded': isExpanded, 'with-assistant-sidebar': showAssistantSidebar }"
      >
        <div class="multi-product-layout__inner">
          <div
            class="multi-product-layout__inner-scroll wide-normal"
            :style="{ height: computedHeight }"
          >
            <global-banner
              v-if="userData"
              :user-data="userData"
              :type="type"
            />

            <div :class="type">
              <slot>
                Page not found.
              </slot>
            </div>
          </div>
        </div>
      </main>

      <assistant-sidebar
        v-if="showAssistantSidebar"
        :is-collapsed="isAssistantSidebarCollapsed"
        context="chat-create-workflow"
        @update:width="handleAssistantSidebarWidth"
        @collapse="handleAssistantSidebarCollapse"
      />

      <button
        v-if="showAssistantSidebar"
        class="loopy-trigger-cta"
        data-test-id="assistant-sidebar-toggle"
        @click="handleAssistantSidebarOpen({ detail: { eventType: trackingEventProperties.WORKFLOW_ASSISTANT_ICON } })"
      >
        <tool-tip
          position="left"
        >
          <img
            :src="'/img/assistant-logo.svg'"
            alt="assistant sidebar toggle"
          />

          <template #content>
            {{ isAssistantSidebarCollapsed ? 'Show Assistant Sidebar' : 'Hide Assistant Sidebar' }}
          </template>
        </tool-tip>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import GlobalBanner from '@/components/feedback/GlobalBanner';
import MultiProductNavSidebar from '@/components/layout/MultiProduct/MultiProductSidebar';
import NavigationHeader from '@/components/layout/NavigationHeader';
import LoadingPage from '@/pages/States/LoadingPage';
import AssistantSidebar from '@/components/layout/MultiProduct/AssistantSidebar';
import { ToolTip } from '@loophq/ui';
import { trackingEventProperties } from '@/constants/segment';
import { useAssistantSidebar } from '@/composables/useAssistantSidebar';

defineProps({
  userData: {
    type: Object,
    required: false
  },
  type: {
    type: String,
    required: false,
    default() {
      return 'normal';
    },
    validator(value) {
      return ['full', 'normal', 'wide'].includes(value);
    }
  },
});

const windowHeight = ref(window.innerHeight);
const isExpanded = ref(false);
const isSidebarVisible = ref(false);

const sidebar = ref(null);
const header = ref(null);

const {
  isAssistantSidebarCollapsed,
  showAssistantSidebar,
  handleAssistantSidebarWidth,
  handleAssistantSidebarCollapse,
  handleAssistantSidebarOpen,
  setupAssistantSidebarListeners
} = useAssistantSidebar();

const toggleStyles = (payload) => {
  isExpanded.value = payload;
};

const toggleSidebar = () => {
  isSidebarVisible.value = !isSidebarVisible.value;
};

const computedHeight = computed(() => {
  //Account for the nav bar height
  return `${windowHeight.value - 72}px`;
});

const updateHeight = () => {
  windowHeight.value = window.innerHeight;
};

const sidebarSizeHandler = () => {
  if (sidebar.value?.$el?.clientWidth) {
    document.documentElement.style.setProperty('--nav-width-new-admin', sidebar.value?.$el?.clientWidth + 'px');
  }
};

const headerSizeHandler = () => {
  if (header.value?.$el?.clientHeight) {
    document.documentElement.style.setProperty('--nav-bar-height', header.value?.$el?.clientHeight + 'px');
  }
};

onMounted(() => {
  window.addEventListener('resize', updateHeight);
  document.body.style.overflowY = 'hidden';

  // Setup assistant sidebar listeners and store cleanup function
  const cleanup = setupAssistantSidebarListeners();
  onUnmounted(cleanup);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateHeight);
  document.body.style.overflowY = '';
});

</script>

<style lang="scss">
/* Remove scoped to allow global styles */
:root {
  .multi-product-layout.has-assistant-sidebar ~ #front-chat-iframe {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
.multi-product-layout {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-nav-background);

  :deep(.save-bar) {
    width: calc(100% - var(--nav-width-new-admin));
  }

  &__wrapper {
    width: 100%;
  }

  &__header {
    position: sticky;
    top: 0;
    right: 0;
    background: var(--color-nav-background);
    pointer-events: all;
    flex-shrink: 0;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
  }

  &__inner {
    border-top-left-radius: 16px;
    background: white;
    overflow-y: hidden;
    height: 100%;

    &-scroll {
      overflow-y: scroll;
      height: 100%;
      position: relative;

      &.wide-normal {
        padding: 0 var(--spacing-700);
      }
    }
  }

  &__banner {
    margin-bottom: var(--spacing-300);
  }

  &__content {
    padding-left: 276px !important;
    position: relative;
    width: 100%;
    //stylelint-disable-next-line
    padding: 1.5rem 2rem 5.5rem;
    box-sizing: border-box;
    border-top-left-radius: var(--border-radius-small);

    &.is-expanded {
      padding-left: 348px !important;
    }

    &.full {
      padding: 0;
      max-width: none;

      .layout__banner {
        margin-top: var(--spacing-300);
      }
    }

    // This is necessary to prevent the dark background of the page from being visible while the
    // assistant sidebar's transition plays out.
    &::before {
      background: white;
      bottom: 0;
      content: '';
      display: block;
      max-width: 600px; // This is the maximum width of the assistant sidebar.
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  //These are outside of the content div, since we only want to apply it to the innermost div
  .normal {
    max-width: 1080px;
    margin: 0 auto;
  }

  &.wide {
    max-width: 1440px;
    margin: 0 auto;
  }

  &.hourglass {
    padding: 0;
    background: white;
  }

  &.has-assistant-sidebar {
    .multi-product-layout__content {
      padding-right: var(--assistant-sidebar-width, 300px);
      transition: padding-right 0.3s ease;
      will-change: padding-right;

      &.with-assistant-sidebar {
        :deep(.save-bar) {
          width: calc(100% - var(--nav-width-new-admin) - var(--assistant-sidebar-width, 300px));
          transition: width 0.3s ease;
        }
      }
    }
  }
}

@media screen and (width <= 960px) {
  .multi-product-layout {
    padding-left: 0 !important;

    &__content {
      padding: 0 !important;

      &.is-expanded {
        padding-left: 0 !important;
      }
    }

    &__inner {
      border-radius: 0;
    }

    &__sidebar {
      display: none;
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: 100%;
      background-color: var(--color-nav-background);

      &.is-visible {
        display: block;
      }
    }

    &.has-assistant-sidebar {
      .multi-product-layout__content {
        padding-right: 0 !important;
      }
    }
  }
}

.loopy-trigger-cta {
  position: fixed;
  bottom: 30px;
  height: 50px;
  min-width: 0;
  padding: 0;
  right: 30px;
  width: 50px;
  border: none;
  cursor: pointer;
  z-index: 99;

  &:focus {
    outline: 2px solid var(--color-neutral-900);
    outline-offset: 2px;
  }

  @media screen and (width <= 960px) {
    right: 24px !important;
  }
}
</style>
