import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { featureFlags } from '@/util/schemas/featureFlags';
import { identify, track } from '@/util/helpers/segment';
import { trackingEvents } from '@/constants/segment';

export function useAssistantSidebar() {
  const store = useStore();
  const route = useRoute();

  const assistantSidebarWidth = ref(parseInt(localStorage.getItem('assistantSidebarWidth')) || 300);
  const isAssistantSidebarCollapsed = ref(localStorage.getItem('assistantSidebarCollapsed') !== 'false');

  const hasAssistantFeatureFlag = computed(() => store.getters.hasFeature(featureFlags.LOOPY_AI));
  const showAssistantSidebar = computed(() => {
    const routeName = route?.name;
    return hasAssistantFeatureFlag.value && routeName === 'WorkflowsList';
  });

  const handleAssistantSidebarWidth = (width) => {
    assistantSidebarWidth.value = width;
  };

  const handleAssistantSidebarCollapse = (collapsed) => {
    isAssistantSidebarCollapsed.value = collapsed;
  };

  const handleAssistantSidebarOpen = (e) => {
    isAssistantSidebarCollapsed.value = !isAssistantSidebarCollapsed.value;
    localStorage.setItem('assistantSidebarCollapsed', isAssistantSidebarCollapsed.value);

    if (showAssistantSidebar.value) {
      identify(store.state.userData, store.state.userData.shop);

      track(trackingEvents.WORKFLOW_ASSISTANT_OPENED, {
        userId: store.state.userData.id,
        shopId: store.state.userData.shop_id,
        entryPoint: e.detail?.entryPoint,
      });
    }
  };

  const setupAssistantSidebarListeners = () => {
    window.addEventListener('open-assistant-sidebar', handleAssistantSidebarOpen);
    return () => {
      window.removeEventListener('open-assistant-sidebar', handleAssistantSidebarOpen);
    };
  };

  return {
    assistantSidebarWidth,
    isAssistantSidebarCollapsed,
    showAssistantSidebar,
    handleAssistantSidebarWidth,
    handleAssistantSidebarCollapse,
    handleAssistantSidebarOpen,
    setupAssistantSidebarListeners
  };
}
