import Store from '@/store';
import { permissions } from '@/util/schemas/permissions';
import { featureFlags } from '@/util/schemas/featureFlags';
import { shopSettings } from '@/util/schemas/ShopSettings';
import { NavItem } from '@/util/typedefs/NavItem';

export const primaryNavItems: NavItem[] = [
  {
    name: 'Home',
    icon: 'home',
    route: { name: 'MissionControl' },
    isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS)
      && Store.getters.hasPermission(permissions.MANAGE_REPORTING)
      && Store.getters.hasPermission(permissions.VIEW_ANALYTICS)
  },
  {
    name: 'Returns management',
    route: { name: 'ReturnsList' },
    icon: 'inbox',
    iconBubble: 'inbox-new',
    bubble: () => Store.state.returns.feed.newReturns || null,
    children: [
      {
        name: 'Returns',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURNS),
        children: [
          {
            name: 'Return processing',
            route: { name: 'ReturnsList' },
            url: '/returns',
          },
          {
            name: 'Warranty processing',
            route: { name: 'WarrantyList' },
            url: '/returns/warranties',
            isVisible: () => Store.getters.getSetting('WARRANTIES_ENABLED'),
          },
          {
            name: 'Blocklist & allowlist',
            route: { name: 'ListingList' },
            url: '/listings',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_LISTINGS),
          },
          {
            name: 'Find an order',
            route: { name: 'ReturnsFindOrder' },
            url: '/returns/find-order',
          },
          {
            name: 'Return events feed',
            route: { name: 'ReturnsFeed' },
            url: '/returns/feed',
          }
        ]
      },
      {
        name: 'Analytics',
        icon: 'activity',
        isVisible: () => Store.getters.hasPermission(permissions.VIEW_ANALYTICS) && !Store.getters.hasFeature(featureFlags.GOODDATA_ANALYTICS),
        children: [
          {
            name: 'Reports',
            route: { name: 'AnalyticsReports' },
            url: '/analytics/reports',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
          },
          {
            name: 'Overview',
            route: { name: 'AdvancedInsights', params: { slug: 'overview' } },
            url: '/analytics/overview',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            name: 'Value impact',
            route: { name: 'AdvancedInsights', params: { slug: 'loopvalueimpact' } },
            url: '/analytics/loopvalueimpact',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
          },
          {
            name: 'Products',
            route: { name: 'AdvancedInsights', params: { slug: 'products' } },
            url: '/analytics/products',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            name: 'Customers',
            route: { name: 'AdvancedInsights', params: { slug: 'customers' } },
            url: '/analytics/customers',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            name: 'Operations',
            route: { name: 'AdvancedInsights', params: { slug: 'operations' } },
            url: '/analytics/operations',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            name: 'Shipping & logistics',
            route: { name: 'AdvancedInsights', params: { slug: 'shippinglogistics' } },
            url: '/analytics/shippinglogistics',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING)
          },
          {
            name: 'Happy Returns',
            route: { name: 'AdvancedInsights', params: { slug: 'happyreturns' } },
            url: '/analytics/happyreturns',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
            isVisible: () => Store.getters.getSetting('INTEGRATION_HAPPY_RETURNS_ENABLED')
          },
          {
            name: 'Happy Returns shipments',
            route: { name: 'AdvancedInsights', params: { slug: 'happyreturnsshipments' } },
            url: '/analytics/happyreturnsshipments',
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
            isVisible: () => Store.getters.getSetting('INTEGRATION_HAPPY_RETURNS_ENABLED')
          },
        ],
      },
      {
        name: 'Policy settings',
        icon: 'shield',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_GENERAL_SETTINGS),
        children: [
          {
            name: 'General',
            route: { name: 'PolicyList' },
            url: '/settings/general',
          },
          {
            name: 'Return policies',
            route: { name: 'ZoneList' },
            url: '/settings/return-policy',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_RETURN_POLICIES),
          },
          {
            name: 'Warranty policies',
            route: { name: 'WarrantiesList' },
            url: '/settings/warranty-policy',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_WARRANTIES) && Store.getters.getSetting('WARRANTIES_ENABLED'),
          },
          {
            name: 'Workflows',
            route: { name: 'WorkflowsList' },
            url: '/workflows',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_WORKFLOWS),
          },
          {
            name: 'Policy rules',
            route: { name: 'Policy Rules' },
            url: '/settings/rules',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_POLICY_RULES) && !Store.getters.getSetting('HIDE_POLICY_RULES'),
          },
          {
            name: 'Bundles',
            route: { name: 'Bundles' },
            url: '/settings/bundles',
            isVisible: () => Store.getters.getSetting('BUNDLES_ENABLED') && Store.getters.getSetting('BUNDLE_PROVIDER') === 'loop' && Store.getters.hasPermission(permissions.MANAGE_BUNDLES),
          },
          {
            name: 'Shop Now',
            route: { name: 'ShopNow' },
            url: '/shop-now',
            isVisible: () => Store.getters.getSetting('SHOP_NOW_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_SHOP_NOW),
          },
          {
            name: 'Advanced Exchanges',
            route: { name: 'exchangesList' },
            url: '/advanced-exchanges',
            isVisible: () => Store.getters.getSetting('ADVANCED_EXCHANGES_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_ADVANCED_EXCHANGES),
          },
        ],
      },
      {
        name: 'Shopper experience',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_CUSTOMIZATIONS),
        children: [
          {
            name: 'Portal customizations',
            route: { name: 'CustomizationsList' },
            url: '/settings/customizations',
          },
          {
            name: 'Return reasons',
            route: { name: 'ReasonsList' },
            url: '/settings/reasons',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_REASONS),
          },
          {
            name: 'Notifications',
            route: { name: 'NotificationsList' },
            url: '/settings/notifications',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_NOTIFICATIONS),
          },
        ],
      },
      {
        name: 'Shipping',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_SHIPPING_SERVICES),
        children: [
          {
            name: 'Shipping services',
            route: { name: 'Shipping' },
            url: '/settings/shipping',
          },
          {
            name: 'Destinations',
            route: { name: 'DestinationsList' },
            url: '/settings/destinations',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_DESTINATIONS),
          },
          {
            name: 'Locations',
            route: { name: 'LocationsPage' },
            url: '/settings/locations',
            isVisible: () => Store.getters.hasPermission(permissions.MANAGE_FULFILLABLE_LOCATIONS),
          },
        ]
      },
      {
        name: 'Tools & integrations',
        isVisible: () => Store.getters.hasPermission(permissions.MANAGE_INTEGRATIONS),
        children: [
          {
            name: 'Integrations',
            route: { name: 'Integrations' },
            url: '/settings/integrations',
          },
          {
            name: 'Developer tools',
            route: { name: 'DevelopersPage' },
            url: '/settings/developers',
            isVisible: () => Store.getters.getSetting('DEVELOPER_TOOLS_ENABLED') && Store.getters.hasPermission(permissions.MANAGE_DEVELOPER_TOOLS),
          }
        ],
      }
    ]
  },
  {
    name: 'Analytics',
    icon: 'activity',
    route: { name: 'AnalyticsReports' },
    badge: () => Store.getters.hasPermission(permissions.VIEW_ANALYTICS) && Store.getters.hasFeature(featureFlags.GOODDATA_ANALYTICS) ? { text: 'New', type: 'progress' } : null,
    isVisible: () => Store.getters.hasPermission(permissions.VIEW_ANALYTICS) && Store.getters.hasFeature(featureFlags.GOODDATA_ANALYTICS),
    children: [
      {
        name: 'Reports',
        route: { name: 'AnalyticsReports' },
        url: '/analytics/reports',
        isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
        children: []
      },
      {
        name: 'Dashboards',
        isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
        children: [
          {
            name: 'Returns',
            route: { name: 'AdvancedInsights', params: { slug: 'returns' } },
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
            url: '/analytics/returns',
          },
          {
            name: 'Logistics',
            route: { name: 'AdvancedInsights', params: { slug: 'logistics' } },
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
            url: '/analytics/logistics',
          },
          {
            name: 'Merchandising',
            route: { name: 'AdvancedInsights', params: { slug: 'merchandising' } },
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
            url: '/analytics/merchandising',
          },
          {
            name: 'Customers',
            route: { name: 'AdvancedInsights', params: { slug: 'customers' } },
            isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
            url: '/analytics/customers',
          },
        ]
      },
      {
        name: 'Private',
        route: { name: 'DashboardsList', params: { type: 'private' } },
        url: '/dashboards/private',
        isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
        children: []
      },
      {
        name: 'Shared',
        route: { name: 'DashboardsList', params: { type: 'shared' } },
        url: '/dashboards/shared',
        isDisabled: () => !Store.getters.hasPermission(permissions.MANAGE_REPORTING),
        children: []
      },
    ],
  },
  {
    name: 'Tracking',
    route: { name: 'Order Tracking' },
    icon: 'destinations',
    badge: () => !Store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED) ? {text: 'New', type: 'progress'} : null,
    children: [
      {
        name: 'Wonderment',
        route: { name: 'Order Tracking' },
        url: () => {
          if (Store.getters.getSetting(shopSettings.ORDER_TRACKING_ALLOWED)) {
            return '/settings/order-tracking';
          }
          return '/settings/get-order-tracking';
        },
        children: []
      },
      {
        name: 'Return and exchange tracking',
        route: { name: 'Return and Exchange Tracking' },
        url: '/settings/return-and-exchange-tracking',
        isVisible: () => Store.getters.getSetting(shopSettings.RETURN_TRACKING_AVAILABLE),
        children: []
      }
    ]
  },
  {
    name: 'Point of Sale',
    route: { name: 'PointOfSaleList' },
    url: () => {
      if (Store.getters.getSetting(shopSettings.ENABLE_POINT_OF_SALE)) {
        return '/settings/point-of-sale';
      }
      return '/settings/get-point-of-sale';
    },
    icon: 'smartphone',
  },
  {
    name: 'Order Protection',
    route: { name: 'ShippingProtection' },
    icon: 'lock',
    isVisible: () => Store.getters.getSetting(shopSettings.SHIPPING_PROTECTION_ENABLED),
    children: [
      {
        name: 'Package Protection',
        route: { name: 'ShippingProtection' },
        url: '/settings/protection',
      },
    ],
  }
];

export const bottomNavItems: NavItem[] = [
  {
    name: 'Onboarding hub',
    route: { name: 'OnboardingDashboard' },
    url: '/onboarding-hub',
    icon: 'clipboard-check',
    isVisible: () => Store.getters.user.has_onboarding_modules,
    children: [
      {
        name: 'Basic setup',
        route: { name: 'OnboardingHubBasicSetup' },
      },
      {
        name: 'Advanced setup',
        route: { name: 'OnboardingHubAdvancedSetup' },
      },
    ]
  },
  {
    name: 'Shopper return portal',
    route: () => { return Store.getters['customerPortalLink']; },
    icon: 'external-link',
    isExternal: true,
  },
  {
    name: 'Help Center',
    route: 'https://help.loopreturns.com',
    icon: 'help-circle',
    isExternal: true,
  }
];
