//@TODO -- See if we can use this to load modules dynamically -- until then, keep all imports in this file
import languages from './languages';
import advancedExchanges from './advancedExchanges';
import customsTerritories from './customsTerritories';
import listings from './listings';
import onboarding from './onboarding';
import orders from './orders';
import reasons from './reasons';
import regions from './regions';
import returnMethods from './returnMethods';
import returns from './returns';
import rules from './rules';
import settings from './settings';
import shopnow from './shopnow';
import returnsDetail from './returns/detail';
import feed from './returns/feed';
import rulesDetail from './policyRules/detail';
import account from './settings/account';
import billing from './settings/billing';
import bundles from './settings/bundles';
import customizations from './settings/customizations';
import destinations from './settings/destinations';
import developers from './settings/developers';
import integrations from './settings/integrations';
import locations from './settings/locations';
import manual from './settings/manual';
import notifications from './settings/notifications';
import policies from './settings/policies';
import reasonSettings from './settings/reasons';
import role from './settings/role';
import shipByLoop from './settings/shipByLoop';
import shipping from './settings/shipping';
import warranties from './settings/warranties';
import workflows from './settings/workflows';
import workflowGroups from './settings/workflowGroups';
import analytics from './analytics';

const modules = {
  languages,
  advancedExchanges,
  customsTerritories,
  listings,
  onboarding,
  orders,
  reasons,
  regions,
  returnMethods,
  returns: {
    namespaced: true,
    returns,
    modules: {
      detail: returnsDetail,
      feed,
    }
  },
  rules,
  policyRulesDetail: rulesDetail,
  settings: {
    namespaced: true,
    settings,
    modules: {
      account,
      billing,
      bundles,
      customizations,
      destinations,
      developers,
      integrations,
      locations,
      manual,
      notifications,
      policies,
      reasons: reasonSettings,
      role,
      shipping,
      shipByLoop,
      warranties,
      workflows,
      workflowGroups
    }
  },
  shopnow,
  analytics,
};
export default modules;
