import { ref, onMounted, onUnmounted } from 'vue';
import { track, identify, initSegment } from '@/util/helpers/segment';
import { trackingEvents } from '@/constants/segment';

// Constants for timing values
const QUEUE_PROCESSING_DELAY = 1000; // ms
const DEFAULT_TYPING_DELAY = 2000; // ms
const QUEUE_BATCH_SIZE = 10; // Number of events to process in a single batch

// Constants for validation
const DEFAULT_SEARCH_RESULTS = { hasResults: false, count: 0, names: [] };

/**
 * Composable for tracking search-related events
 * 
 * @param {Object} options - Configuration options
 * @param {Function} options.getUserData - Function that returns the current user data
 * @returns {Object} - Search tracking methods and state
 */
export default function useSearchTracking({ getUserData }) {
  // Track the last query that was sent to analytics
  const lastTrackedQuery = ref('');
  
  /**
   * Set to keep track of all queries that have been tracked
   * This prevents duplicate tracking and helps with test cases
   * where we need to know if a query has been tracked before
   */
  const trackedQueries = new Set();
  
  // Flag to track if user is currently typing
  const isTyping = ref(false);
  // Timer reference for the typing session
  let typingTimer = null;
  // Current typing session ID to prevent race conditions
  let currentTypingSessionId = 0;
  // Flag to track if Segment is initialized
  const isSegmentInitialized = ref(false);
  // Queue for tracking events that couldn't be sent immediately
  const trackingQueue = ref([]);
  // Lock for tracking operations to prevent race conditions
  const isTrackingLocked = ref(false);

  /**
   * Helper method to clear any pending typing timer and invalidate sessions
   */
  const clearTypingTimer = () => {
    if (typingTimer) {
      clearTimeout(typingTimer);
      typingTimer = null;
      // Increment session ID to invalidate any pending typing sessions
      currentTypingSessionId++;
    }
  };

  /**
   * Helper method to safely get user data
   * @returns {Object|null} User data or null if not available
   */
  const getSafeUserData = () => {
    return getUserData ? getUserData() : null;
  };

  /**
   * Helper method to format result names for tracking
   * @param {Array} names - Array of result names
   * @returns {Array|undefined} - Formatted names or undefined if empty
   */
  const formatResultNames = (names) => {
    return names && names.length > 0 ? names : undefined;
  };

  /**
   * Helper method to safely get search results with consistent error handling
   * @param {Function} getSearchResults - Function that returns search results data
   * @returns {Object} Search results with default values if function fails
   */
  const getSafeSearchResults = (getSearchResults) => {
    if (!getSearchResults) {
      return DEFAULT_SEARCH_RESULTS;
    }
    
    try {
      const results = getSearchResults();
      return {
        hasResults: results.hasResults || false,
        count: results.count || 0,
        names: results.names || []
      };
    } catch (e) {
      console.error('Error getting search results:', e);
      return DEFAULT_SEARCH_RESULTS;
    }
  };

  /**
   * Check if Segment is initialized
   * @returns {boolean} Whether Segment is initialized
   */
  const checkSegmentInitialization = () => {
    return typeof window !== 'undefined' && 
           window.analytics && 
           window.analytics.initialized;
  };

  /**
   * Initialize Segment if needed
   * @returns {boolean} Whether Segment is initialized after this operation
   */
  const ensureSegmentInitialized = () => {
    // First check the cached value
    if (isSegmentInitialized.value) {
      return true;
    }
    
    // Then check the actual state
    isSegmentInitialized.value = checkSegmentInitialization();
    
    // If still not initialized, try to initialize
    if (!isSegmentInitialized.value) {
      try {
        initSegment();
        isSegmentInitialized.value = checkSegmentInitialization();
      } catch (e) {
        console.error('Failed to initialize Segment analytics:', e);
      }
    }
    
    return isSegmentInitialized.value;
  };

  /**
   * Process any queued tracking events
   */
  const processTrackingQueue = () => {
    if (trackingQueue.value.length > 0 && ensureSegmentInitialized()) {
      // Process in batches to avoid performance issues with large queues
      const itemsToProcess = trackingQueue.value.slice(0, QUEUE_BATCH_SIZE);
      
      itemsToProcess.forEach(item => {
        if (item.type === 'track') {
          track(item.event, item.properties);
        } else if (item.type === 'identify') {
          identify(item.user, item.shop);
        }
      });
      
      // Remove processed items
      trackingQueue.value = trackingQueue.value.slice(QUEUE_BATCH_SIZE);
      
      // If there are more items, schedule another processing
      if (trackingQueue.value.length > 0) {
        setTimeout(processTrackingQueue, QUEUE_PROCESSING_DELAY);
      }
    }
  };

  /**
   * Safe wrapper for track function
   * @param {string} event - The event name to track
   * @param {Object} properties - The event properties
   */
  const safeTrack = (event, properties) => {
    if (ensureSegmentInitialized()) {
      track(event, properties);
    } else {
      trackingQueue.value.push({
        type: 'track',
        event,
        properties
      });
      // Try to process the queue after a short delay
      setTimeout(processTrackingQueue, QUEUE_PROCESSING_DELAY);
    }
  };

  /**
   * Safe wrapper for identify function
   * @param {Object} user - The user to identify
   * @param {Object} shop - The shop associated with the user
   */
  const safeIdentify = (user, shop) => {
    if (ensureSegmentInitialized()) {
      identify(user, shop);
    } else {
      trackingQueue.value.push({
        type: 'identify',
        user,
        shop
      });
      // Try to process the queue after a short delay
      setTimeout(processTrackingQueue, QUEUE_PROCESSING_DELAY);
    }
  };

  /**
   * Track a search query event
   * 
   * @param {Object} params - Search parameters
   * @param {string} params.query - The search query
   * @param {boolean} params.hasResults - Whether the search returned results
   * @param {number} params.resultsCount - Number of results returned
   * @param {Array} [params.resultNames] - Names of the results (optional)
   * @param {string} [params.action='searched'] - The search action
   * @param {boolean} [params.preserveLastTrackedQuery=false] - Whether to preserve the last tracked query
   */
  const trackSearchQuery = ({ 
    query, 
    hasResults, 
    resultsCount, 
    resultNames, 
    action = 'searched',
    preserveLastTrackedQuery = false
  }) => {
    // Validate query is a string
    if (typeof query !== 'string') {
      console.error('Invalid query type. Expected string, got:', typeof query);
      return;
    }
    
    // Don't track empty queries or queries we've already tracked
    if (query === '' || (query === lastTrackedQuery.value && action === 'searched')) {
      return;
    }
    
    // Prevent concurrent tracking operations that could interfere with each other
    if (isTrackingLocked.value) {
      return;
    }
    
    isTrackingLocked.value = true;
    
    // Store the original lastTrackedQuery value if we need to preserve it
    const originalLastTrackedQuery = preserveLastTrackedQuery ? lastTrackedQuery.value : null;
    
    const userData = getSafeUserData();
    
    // Identify user before tracking the event
    if (userData) {
      safeIdentify(userData, userData.shop);
    }
    
    const searchData = {
      query,
      action,
      hasResults,
      resultsCount,
    };
    
    // Only include result names if provided
    if (resultNames && resultNames.length > 0) {
      searchData.resultNames = resultNames;
    }
    
    // Update the last tracked query for 'searched' actions
    if (action === 'searched' && !preserveLastTrackedQuery) {
      lastTrackedQuery.value = query;
      // Add to the set of tracked queries
      trackedQueries.add(query);
    }
    
    // Send the tracking event
    safeTrack(trackingEvents.GLOBAL_SEARCH_PERFORMED, searchData);
    
    // Restore the original lastTrackedQuery value if needed
    if (preserveLastTrackedQuery && originalLastTrackedQuery !== null && originalLastTrackedQuery !== '') {
      lastTrackedQuery.value = originalLastTrackedQuery;
    }
    
    isTrackingLocked.value = false;
  };

  /**
   * Track a search result click event
   * 
   * @param {Object} params - Click parameters
   * @param {string} params.query - The search query
   * @param {string} params.page - The URL of the clicked result
   */
  const trackResultClick = ({ query, page }) => {
    const userData = getSafeUserData();
    
    // Identify user before tracking the event
    if (userData) {
      safeIdentify(userData, userData.shop);
    }
    
    safeTrack(trackingEvents.GLOBAL_SEARCH_RESULT_CLICKED, {
      query,
      page,
    });
  };

  /**
   * Start tracking a typing session
   * 
   * @param {Object} params - Typing parameters
   * @param {string} params.query - The current query
   * @param {Function} params.getSearchResults - Function that returns search results data
   * @param {number} [params.delay=DEFAULT_TYPING_DELAY] - Delay in ms before tracking
   */
  const startTypingSession = ({ query, getSearchResults, delay = DEFAULT_TYPING_DELAY }) => {
    // Set typing flag to true
    isTyping.value = true;
    
    // Clear any existing timer
    clearTypingTimer();
    
    // Generate a new session ID for this typing session
    const sessionId = ++currentTypingSessionId;
    
    // Set a new timer to track the query after user stops typing
    typingTimer = setTimeout(() => {
      // Only track if we're still in the same typing session (prevents race conditions)
      if (isTyping.value && sessionId === currentTypingSessionId) {
        isTyping.value = false;
        
        // Get search results using the safe helper
        const results = getSafeSearchResults(getSearchResults);
        
        trackSearchQuery({
          query,
          hasResults: results.hasResults,
          resultsCount: results.count,
          resultNames: formatResultNames(results.names),
        });
      }
    }, delay);
  };

  /**
   * Handle explicit search submission
   * 
   * @param {Object} params - Submission parameters
   * @param {string} params.query - The search query
   * @param {Function} params.getSearchResults - Function that returns search results data
   */
  const handleSearchSubmit = ({ query, getSearchResults }) => {
    if (query === '' || query === lastTrackedQuery.value) return;
    
    // Clear any pending typing timer
    clearTypingTimer();
    
    // Reset typing flag
    isTyping.value = false;
    
    // Get search results using the safe helper
    const results = getSafeSearchResults(getSearchResults);
    
    // Track the search query
    trackSearchQuery({
      query,
      hasResults: results.hasResults,
      resultsCount: results.count,
      resultNames: formatResultNames(results.names),
    });
  };

  /**
   * Handle result click, ensuring the search is tracked first
   * 
   * @param {Object} params - Click parameters
   * @param {string} params.query - The search query
   * @param {string} params.page - The URL of the clicked result
   * @param {Function} params.getSearchResults - Function that returns search results data
   */
  const handleResultClick = ({ query, page, getSearchResults }) => {
    // Cancel any pending typing timer
    clearTypingTimer();
    
    // Reset typing flag
    isTyping.value = false;
    
    // First, ensure the search query itself is tracked if it hasn't been already
    // AND if it's not already in the set of tracked queries
    if (query !== '' && !trackedQueries.has(query)) {
      // Get search results using the safe helper
      const results = getSafeSearchResults(getSearchResults);
      
      // Track the search query
      trackSearchQuery({
        query,
        hasResults: results.hasResults,
        resultsCount: results.count,
        resultNames: formatResultNames(results.names),
      });
    }
    
    // Track the result click
    trackResultClick({ query, page });
  };

  /**
   * Handle search clear, ensuring the search is tracked first
   * 
   * @param {Object} params - Clear parameters
   * @param {string} params.query - The search query
   * @param {Function} params.getSearchResults - Function that returns search results data
   */
  const handleSearchClear = ({ query, getSearchResults }) => {
    if (query === '') return;
    
    // Cancel any pending typing timer
    clearTypingTimer();
    
    // Reset typing flag
    isTyping.value = false;
    
    // Get search results using the safe helper
    const results = getSafeSearchResults(getSearchResults);
    
    // First, ensure the search query itself is tracked if it hasn't been already
    if (!trackedQueries.has(query)) {
      trackSearchQuery({
        query,
        hasResults: results.hasResults,
        resultsCount: results.count,
        resultNames: formatResultNames(results.names),
      });
    }
    
    // Track the clear action
    trackSearchQuery({
      query,
      action: 'cleared',
      hasResults: results.hasResults,
      resultsCount: results.count,
    });
  };

  /**
   * Track when a search returns no results
   * 
   * @param {Object} params - No results parameters
   * @param {string} params.query - The search query
   */
  const trackNoResults = ({ query }) => {
    if (query === '' || isTyping.value) return;
    
    const userData = getSafeUserData();
    
    // Identify user before tracking the event
    if (userData) {
      try {
        safeIdentify(userData, userData.shop);
      } catch (e) {
        console.error('Failed to identify user for no results tracking:', e);
      }
    }
    
    safeTrack(trackingEvents.GLOBAL_SEARCH_PERFORMED, {
      query,
      action: 'no_results',
      hasResults: false,
      resultsCount: 0,
    });
  };

  /**
   * Clean up resources when component is unmounted
   */
  const cleanup = () => {
    clearTypingTimer();
    trackedQueries.clear(); // Clear tracked queries to prevent memory leaks
  };

  // Check Segment initialization on mount
  onMounted(() => {
    // Ensure Segment is initialized and update the flag
    ensureSegmentInitialized();
    
    // Process any queued events
    processTrackingQueue();
  });

  // Clean up resources when component is unmounted
  onUnmounted(() => {
    cleanup();
  });

  return {
    isTyping,
    lastTrackedQuery,
    trackedQueries, // Expose for testing or external use
    startTypingSession,
    handleSearchSubmit,
    handleResultClick,
    handleSearchClear,
    trackNoResults,
    cleanup
  };
} 
