import { actions, miscOperators, operators, rangeOperators, ruleTypes, workflowBuilderRangeOperators } from '@/util/schemas/workflow-rules';
import { getDisplayForRule } from './format';
import { totalConditions } from '@/pages/Workflows/WorkflowsDetail/conditions';
import { createId } from '../ids';

/**
 * 
 * @param {WorkflowBuilderRule} workflowRule 
 * @returns {object}
 */
export const buildRulesFromWorkflowBuilder = (workflowRule) => {
  const rules = [];
  if (!workflowRule) {
    return null;
  }

  // Condition
  rules.push(mapConditions(workflowRule, rules.length));

  // thenActions & elseActions
  if (workflowRule.thenActions?.length > 0) {
    rules.push(...mapActions(workflowRule.thenActions, rules.length));
  }

  if (workflowRule.elseActions?.length > 0) {
    rules.push(...mapActions(workflowRule.elseActions, rules.length));
  }

  // Connector
  // If there are only 2 steps add a connector item for the ui
  if (rules.length === 2) {
    rules.splice(1, 0, createConnector());
  }

  return rules;
};

/**
 * 
 * @param {WorkflowBuilderRule} workflowRule 
 * @param {int} id
 * @returns {WorkflowRule}
 */
const mapConditions = (workflowRule, id) => {
  /** @type {WorkflowBuilderConditionItem[]} */
  const conditions = workflowRule.conditions.map((condition) => {
    return mapConditionItem(condition);
  });

  /** @type {WorkflowRule} */
  return {
    id,
    type: ruleTypes.CONDITION,
    conditions: conditions,
    operator: workflowRule.logicalOperator,
    success: workflowRule.thenActions?.length > 0 ? id + 1 : null,
    fail: workflowRule.elseActions?.length > 0 ? id + 2 : null,
    label: null,
    trigger: true,
  };
};

/**
 * 
 * @param {WorkflowBuilderCondition} condition 
 * @returns {WorkflowBuilderConditionItem}
 */
const mapConditionItem = (condition) => {
  const operator = mapOperators(condition.operator);
  const value = getValuesFromCondition(condition);
  return {
    field: condition.key,
    value: value,
    operator: operator,
    display: getDisplayForRule(operator, value, condition.key),
  };
};

/**
 * Backend Workflows change the values retrned 
 * @param {string} operator
 * @returns {string}
 */
export const mapOperators = (operator) => {
  switch (operator) {
  case workflowBuilderRangeOperators.BETWEEN:
    return rangeOperators.BETWEEN;
  case workflowBuilderRangeOperators.GREATER_THAN:
    return rangeOperators.GREATER_THAN;
  case workflowBuilderRangeOperators.LESS_THAN:
    return rangeOperators.LESS_THAN;
  }

  return operator;
};

/** 
 * @returns {WorkflowRule}
 */
const createConnector = () => {
  return {
    type: ruleTypes.CONDITION,
    label: 'True',
    trigger: false,
    ignore: true,
  };
};

/**
 * Convert the entire action block
 * @param {WorkflowBuilderRule} workflowRule 
 * @param {WorkflowBuilderAction[]} actions 
 * @param {int} id 
 * @returns {WorkflowRule[]}
 */
const mapActions = (actions, id) => {
  const rules = [];
  if (actions.length === 1 && actions[0].rule) {
    const userInput = buildUserInputMap(actions[0].rule, id);
    rules.push(...userInput);
  } else {
    const actionsMap = actions.map((action) => mapActionItem(action, id));

    rules.push({
      actions: actionsMap,
      id: id,
      type: ruleTypes.ACTION,
    });
  }

  return rules;
};

const buildUserInputMap = (rule, id) => {
  const rules = [];
  const newFieldId = `lineItem.user_input_${createId()}`;

  // first user input connector
  rules.push({
    id: id,
    type: ruleTypes.CONDITION,
    operator: miscOperators.MISSING,
    field: newFieldId,
    value: undefined,
    label: 'True',
    trigger: false,
    success: id + 1,
    fail: id + 2,
    display: null,
  });

  // user input card
  rules.push({
    id: id + 1,
    type: ruleTypes.USER_INPUT,
    value: {
      id: newFieldId,
      label: null,
      response: {
        isActive: false,
        message: null,
      },
      type: 'bool',
    },
  });

  // second user input connector
  rules.push({
    id: id + 2,
    display: null,
    field: newFieldId,
    label: 'Yes',
    operator: '==',
    success: id + 3,
    fail: null,
    trigger: false,
    type: ruleTypes.CONDITION,
    value: true,
  });

  // the user input workflow's action component
  rules.push(...mapActions(rule.thenActions, id + 3));

  return rules;
};

/**
 * Convert individual actions from the workflow builder
 * @param {WorkflowBuilderAction} action 
 * @param {int} id
 */
const mapActionItem = (action, id) => {
  if (action.type === actions.BLANK_ACTION) {
    return {
      id: id,
      type: action.type,
      value: getValuesFromAction(action),
    };
  }

  const response = {
    display: action.display,
    field: action.field,
    id: id,
    type: action.type,
    value: getValuesFromAction(action),
  };

  // return window has a stray "windowType" property outside
  // of the value we need to backfill for now
  if (action.type === actions.RETURN_WINDOW) {
    response.windowType = Object.keys(action.value)[0];
  }

  return response;
};

/**
 * 
 * @param {WorkflowBuilderCondition} condition
 * @returns {string|array|WorkflowRuleValueDate|WorkflowRuleValueObject}
 */
const getValuesFromCondition = (condition) => {
  switch (mapOperators(condition.operator)) {
  case operators.BETWEEN:
    return {
      min: formatValues(condition.key, condition.values.value1),
      max: formatValues(condition.key, condition.values.value2),
    };
  case operators.GREATER_THAN:
  case operators.LESS_THAN:
    return {
      min: formatValues(condition.key, condition.values),
      max: 0,
    };
  case operators.BETWEEN_DATE:
    return {
      start: condition.values?.date1,
      end: condition.values?.date2,
    };
  default:
    return condition.values;
  }
};

/**
 * Frontend value mapping for cases where the original value is not what we want
 * e.g. integers cast as strings, empty objects instead of null, unclear keys
 * @param {WorkflowBuilderAction} action 
 */
const getValuesFromAction = (action) => {
  switch (action.type) {
  // blank actions have {} as the value but we would prefer the backend
  // represent this as null
  case actions.BLANK_ACTION:
    return {};
    // handling fee integers were previously returned as string for some reason.
    // these keys are conditionally included in the response, so the spread syntax
    // lets us do that in-line
  case actions.HANDLING_FEES:
    return {
      ...action.value,
      ...(action.value.fallbackAmount && { fallbackAmount: `${action.value.fallbackAmount}` }),
      ...(action.value.maxAmount && { maxAmount: `${action.value.maxAmount}` }),
    };
    // cast int back to string for returnWindow type RETURN_WINDOWs
  case actions.RETURN_WINDOW:
    if (Object.keys(action.value)[0] === 'returnWindow') {
      return {
        returnWindow: `${action.value.returnWindow}`,
      };
    }
    return action.value;
  case actions.DESTINATION_OVERRIDE:
    return {
      destinationIds: action.value,
    };
  default:
    return action.value;
  }
};

/**
 * @param {string} field
 * @param {int} value
 * @returns {int}
 */
const formatValues = (field, value) => {
  return totalConditions.includes(field) ? value / 100 : value;
};
