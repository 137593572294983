import { default as axios, type AxiosResponse } from 'axios';
import changeCase from 'change-object-case';
changeCase.options = { recursive: true, arrayRecursive: true };
const resultToCamelCase = (request: Promise<AxiosResponse<ShipByLoopConfig>>) => {
  return request.then((res) => changeCase.toCamel(res.data));
}
interface PrimaryPaymentMethod {
  last4: string;
  brand: string;
  expirationMonth: number;
  expirationYear: number;
}
interface ShipByLoopConfigInterface {
  id: string;
  accountName: string;
  phone: string;
  email: string;
  primaryPaymentMethod: PrimaryPaymentMethod;
}

export type ShipByLoopConfigKeys = 'id' | 'accountName' | 'phone' | 'email' | 'primaryPaymentMethod';
export type ShipByLoopConfig = Record<ShipByLoopConfigKeys, ShipByLoopConfigInterface>;
export type PartialShipByLoopConfig = Partial<ShipByLoopConfigInterface>;
export type ShippingIntegration = { slug: string }
export type Integration = { shippingIntegration: ShippingIntegration }

export default {
  async getSblId() {
    const req = await axios.get(`shipping`);
    const res = changeCase.toCamel(req.data);

    const sbl = res.integrations.find((integration: Integration) => {
      return integration.shippingIntegration.slug === 'ship-by-loop'
    });

    return sbl?.id;
  },

  getConfig(shippingSettingId: string): Promise<AxiosResponse<ShipByLoopConfig>> {
    return axios.get(`/shipping/integrations/ship-by-loop/configs/${shippingSettingId}`);
  },

  postConfig(data: PartialShipByLoopConfig): Promise<AxiosResponse<ShipByLoopConfig>> {
    try {
      return axios.post('/shipping/integrations/ship-by-loop/configs', data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { message, details } = error.response.data;
        const errorMessage = message && details ? `${message}: ${details}` : message || details;
        return Promise.reject(errorMessage);
      }

      return Promise.reject('An unexpected error occurred.');
    }
  },

  patchConfig(shippingSettingId: string, data: PartialShipByLoopConfig) {
    try {
      return axios.patch(`/shipping/integrations/ship-by-loop/configs/${shippingSettingId}`, data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { message, details } = error.response.data;
        const errorMessage = message && details ? `${message}: ${details}` : message || details;
        return Promise.reject(errorMessage);
      }
    
      return Promise.reject('An unexpected error occurred.');
    }
  },

  deleteConfig(shippingSettingId: string) {
    try {
      return axios.delete(`/shipping/integrations/ship-by-loop/configs/${shippingSettingId}`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { message, details } = error.response.data;
        const errorMessage = message && details ? `${message}: ${details}` : message || details;
        return Promise.reject(errorMessage);
      }
    
      return Promise.reject('An unexpected error occurred.');
    }
  },

  getCarrierShops() {
    return resultToCamelCase(axios.get(`shipping/integrations/ship-by-loop/carrier-shops`));
  },

  addCarrier(data: PartialShipByLoopConfig) {
    return resultToCamelCase(axios.post(`shipping/integrations/ship-by-loop/carrier-shops`, data));
  },

  deleteCarrier(carrierShopId: string) {
    return resultToCamelCase(axios.delete(`shipping/integrations/ship-by-loop/carrier-shops/${carrierShopId}`));
  }
}
