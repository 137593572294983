export const lists = {
  BLOCKLIST: 'blocklists',
  ALLOWLIST: 'allowlists',
};

export const types = {
  CSV: 'csv',
  ORDER: 'order',
  EMAIL: 'email',
  PRODUCT: 'product',
  PRODUCT_TAG: 'product tag'
};
