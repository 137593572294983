import Returns from './returns';

const createReturnsSearch = () => {
  const returnsApi = new Returns();

  const searchReturns = async (query) => {
    try {
      const response = await returnsApi.filter({
        query: query,
        limit: 5,
        offset: 0,
        orderBy: 'createdAt',
        order: 'desc'
      });

      return response.data.returns;
    } catch (err) {
      // Ignore canceled requests as the Returns class already handles this
      if (err.message !== 'Canceled') {
        throw err;
      }
      return [];
    }
  };

  return {
    searchReturns
  };
};

export default createReturnsSearch;
