import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/util/api/auth';
import Error404 from '@/pages/States/404';
import store from '@/store';
import Store from '@/store';
import { initDeferred } from '@/util/initDeferred';
import Cookies from 'js-cookie';
import { enablePageScroll } from 'scroll-lock';
import env from '@/env';
import { auth0 } from '@/util/auth0';
import { ldFlagVariation } from '@/util/lib/launchDarkly';
import { featureFlags } from '@/util/schemas/featureFlags';
import { identify, track } from '@/util/helpers/segment';
import { trackingEvents } from '@/constants/segment';
import { permissions } from '@/util/schemas/permissions';

const modules = import.meta.glob('./*.js', { eager: true });
const routes = [];
for (const path in modules) {
  routes.push(modules[path].default);
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/proxy/:token',
      meta: { user: false },
      component: () => import('@/pages/States/LoadingPage'),
      beforeEnter: async (to, from, next) => {
        let res;
        try {
          res = await auth.proxy(decodeURIComponent(to.params.token));
        } catch (error) {
          return next({ 'name': '404' });
        }

        // Set the intended route as a cookie, so we can redirect to it after log in
        if (res.data.intended?.length) {
          Cookies.set('intended', res.data.intended);
        }

        if (await ldFlagVariation(featureFlags.AUTH0_SESSIONS_ENABLED)) {
          return await router.push(res.data?.intended || '/');
        } else {
          await auth0.loginWithRedirect({
            authorizationParams: {
              organization: env('VITE_AUTH0_LOOP_ORG_ID'),
              connection: 'google-oauth2',
            }
          });
        }
      }
    },
    // Redirect old admin/ routes to new routes
    {
      path: '/admin/*',
      redirect: to => {
        return to.path.replace('/admin', '');
      }
    },
    ...routes.flat(),
    {
      path: '/',
      component: () => import('@/pages/States/LoadingPage'),
      beforeEnter: (to) => {
        if (store.state.userData) {
          const intendedRoute = Cookies.get('intended');
          if (intendedRoute) {
            Cookies.remove('intended');
            return decodeURI(intendedRoute);
          }

          const deepLink = sessionStorage.getItem('deep-link');
          if (deepLink) {
            sessionStorage.removeItem('deep-link');
            return decodeURI(`/${deepLink.slice(1)}`);
          }

          return getFirstRouteTheUserHasAccessTo(store.state.userData);
        } else {
          //check if the user is coming from a shopify install callback route to prevent redirect. This cookie is set in onboarding.js
          if (!Cookies.get('loop-install-cookie') && to.name !== 'Login Callback') {
            return '/login';
          }
        }
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: Error404,
      meta: {
        user: false
      }
    },
  ]
});

// This will refresh the page if there is an error loading an async component due to the JS chunk no longer
// being valid. This usually means the code was re-compiled while a user was on the site.
// @see https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
router.onError(error => {
  if (/loading chunk .* failed/i.test(error.message)) {
    // refresh the page, but let Vue Router handle it
    console.error('Chunk loading error. Reloading page.', error);
    router.go(0);
  }
});

let shouldInitDeferred = false;

// Export this global navigation guard so we can test it
export const beforeEach = async (to, from) => {
  // We want to cancel the navigation if we're coming from the oauth error page so the user can try again
  if (from.path === '/oauth/error') {
    return false;
  }

  if (to.query.shop &&
    to.query.hmac &&
    to.query.timestamp &&
    !to.query.code &&
    !to.query.state &&
    to.name !== 'Callback'
  ) {
    // Install links coming from Shopify have shop, hmac and a timestamp
    // confirmation links coming from shopify include a code and a state in addition to the above.
    // We need to catch install links but pass on confirmation links.
    try {
      // Getting the incoming params and passing them to the initial install step.
      const searchParams = new URLSearchParams(window.location.search);
      const res = await auth.install(searchParams.toString());

      // We're not expecting errors other than "already installed"
      // This will fall through to the login page.
      if (res.data.status === 'error') {
        console.error(res.data.errors[0]);
      } else {
        // Handing off to Shopify for installation.
        window.location.href = res.data.url;
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Load user if needed
  // Ignore active user retrieval if: (1) route meta.user is explicitly false, (2) we're on a callback page,
  // or (3) we have an install cookie set. for these, we will _not_ want or have a user.
  if (to.meta.user !== false && !store.state.userData && to.name !== 'Callback' && !Cookies.get('loop-install-cookie')) {
    try {
      const res = await auth.getCurrentUser();

      store.commit('setUser', res.data);
      shouldInitDeferred = true;
    } catch (error) {
      console.error('Error getting user', error, error.response);
      store.commit('setOauthError', error.response);

      // redirects retrieving active user means we have a user authenticated via SSO (e.g. Wonderment),
      // but they don't exist in the Loop application. for this, we send 'em elsewhere.
      if (error.response?.status === 302 && error.response?.data?.redirect) {
        return window.location.href = error.response.data.redirect;
      }

      // Handle 401 if necessary
      const pathIsIgnored = ['/login', '/sso/authorize', '/login/oauth', 'oauth/callback'].includes(to.fullPath);
      let endpointIsIgnored = false;
      if (error.response?.request?.responseUrl) {
        endpointIsIgnored = ['/authenticate/shopify'].some((endpoint) => {
          return error.response.request.responseUrl?.includes(endpoint);
        });
      }
      const shouldHandle401ForRoute = !pathIsIgnored && !endpointIsIgnored;

      if (error.response?.status === 401 && shouldHandle401ForRoute) {
        console.info('Global Router BeforeEach: User not found, redirecting to login', to.fullPath);
        return { name: 'Login' };
      } else if (to.path === '/') {
        // If we're just hitting the base path, we may have just not logged in yet and don't want to redirect to the login error page. If we're logged in, there will be no error and we'll be redirected accordingly.
        return { name: 'OAuth' };
      } else {
        return { name: 'Login Error' };
      }
    }
  }
};

router.beforeEach((to, from) => beforeEach(to, from));

router.afterEach(async (to, from) => {
  const ignoreLoader = store.state.ignoreLoader;
  const queryParamsChange = to.path === from.path;

  // Set global loader prop, this triggers the global loader
  // Explicit false check is intentional, if it's null we assume true
  if (to.meta.loader !== false && !ignoreLoader && !queryParamsChange) {
    const skeleton = to.meta.skeleton || true;
    store.commit('setLoader', skeleton);
  }

  // Update page title if available
  if (to.meta.title) {
    document.title = `${to.meta.title} | Loop Returns Admin`;
  }

  // Update layout. Defaults to empty layout
  const layout = to.meta.layout || 'app';
  const layoutType = to.meta.layoutType || 'normal';
  store.commit('setLayout', layout);
  store.commit('setLayoutType', layoutType);

  // Some routes use a store action set on the route to grab the data they need
  const storeAction = to.meta.action ?
    store.dispatch(to.meta.action, to) :
    Promise.resolve();

  try {
    await storeAction;
    ga('send', 'pageview');

    //segment page tracking
    if (!queryParamsChange) {
      identify(store.state.userData, store.state.userData?.shop);
      track(trackingEvents.PAGE_VIEW, {
        admin_user_id: store.state.userData?.id,
        shop_id: store.state.userData?.shop_id,
        page_url: to.path,
        timestamp: new Date().getTime(),
        isLoopEmployee: store.state.userData?.email.includes('@loopreturns.com'),
      });
    }

    store.commit('setLoader', false);

  } catch (error) {
    console.error('Router AfterEach', error);
    store.commit('setLoader', false);

  }

  if (shouldInitDeferred) {
    shouldInitDeferred = false;
    await initDeferred(to, store);
  }

  //catch all to enable scroll if it has been locked inadvertently
  enablePageScroll();
});

const getFirstRouteTheUserHasAccessTo = () => {
  let item;

  if (
    Store.getters.hasPermission(permissions.MANAGE_RETURNS)
    && Store.getters.hasPermission(permissions.VIEW_ANALYTICS)
    && Store.getters.hasPermission(permissions.MANAGE_REPORTING)
  ) {
    item = { url: '/home' };
  } else if (Store.getters.hasPermission(permissions.MANAGE_RETURNS)) {
    item = { url: '/returns' };
  } else if (Store.getters.hasPermission(permissions.VIEW_ANALYTICS)) {
    item = { url: '/analytics' };
  } else {
    item = { url: '/' };
  }

  return item ? item.url : '/';
};

export default router;
