<template>
  <div
    class="assistant-sidebar"
    :class="{ 'is-collapsed': isCollapsed }"
  >
    <!-- Close button -->
    <base-button
      type="icon"
      icon="close"
      class="assistant-sidebar__close"
      aria-label="Close sidebar"
      @click="handleClose"
    />

    <!-- Resize handle -->
    <div
      class="assistant-sidebar__resize-handle"
      @mousedown="startResize"
    ></div>

    <!-- Header -->
    <div class="assistant-sidebar__header">
      <div class="assistant-sidebar__header-content">
        <base-text
          color="primary"
          tag="h2"
          type="text-h2"
        >
          Workflows Assistant
        </base-text>

        <status-badge type="default">
          BETA
        </status-badge>
      </div>
    </div>

    <!-- Scrollable content -->
    <div class="assistant-sidebar__content">
      <div
        ref="chatMessageWrapper"
        class="assistant-sidebar__content-inner"
      >
        <div class="chat-messages">
          <div class="chat-message">
            <div class="chat-message__bubble chat-message--assistant">
              <base-text type="text-body">
                Hi! Ask me things about Workflows like:

                <ul class="mb-0">
                  <li>What does Workflows do?</li>

                  <li>Can you make final sale items non-returnable?</li>

                  <li>Can you remove handling fees for my VIP customers?</li>
                </ul>
              </base-text>
            </div>
          </div>

          <div
            v-for="(message, index) in formattedMessages"
            :key="`message-${index}`"
            class="chat-message"
            :class="chatBubbleClass(message)"
          >
            <div
              v-if="message.role === 'user' || message.role === 'assistant'"
              class="chat-message__bubble"
            >
              <base-text
                type="text-body"
                v-html="message.content"
              />
            </div>

            <assistant-tool
              v-else-if="message.role === 'tool'"
              :message="message"
              @message="handleToolMessage"
            />
          </div>

          <div
            v-if="isThinking"
            class="chat-message"
          >
            <span class="thinking-spinner"></span> Thinking...
          </div>
        </div>
      </div>
    </div>

    <!-- Fixed bottom form -->
    <div class="assistant-sidebar__footer">
      <form
        class="assistant-sidebar__form"
        @submit.prevent="sendMessage"
      >
        <base-input
          v-model="inputText"
          placeholder="Type a message to Workflows Assistant..."
          class="assistant-sidebar__input"
          @keydown.enter.prevent.stop="sendMessage"
        />
      </form>
    </div>

    <!-- Rating overlay -->
    <div
      v-if="isRatingShown"
      class="assistant-rating"
    >
      <base-text
        class="assistant-rating__title"
        color="primary"
        tag="h2"
        type="text-h2"
      >
        How would you rate your experience with the Workflows Assistant?
      </base-text>

      <div class="assistant-rating__ratings">
        <base-button
          class="assistant-rating__rating"
          type="primary"
          icon="thumbs-up"
          :activated="isSubmittingFeedback"
          @click="handleThumbsUp"
        >
          Great
        </base-button>

        <base-button
          class="assistant-rating__rating"
          type="primary"
          variant="destructive"
          icon="thumbs-down"
          :activated="isSubmittingFeedback"
          @click="handleThumbsDown"
        >
          Needs work
        </base-button>
      </div>

      <div
        v-if="isAdditionalFeedbackShown"
        class="assistant-rating__feedback"
      >
        <base-input
          v-model="additionalFeedback"
          class="assistant-rating__feedback-input"
          type="text"
          component-type="textarea"
          label="We would love to hear your feedback!"
          show-label
        />

        <base-button
          class="assistant-rating__feedback-cta"
          type="primary"
          :activated="isSubmittingFeedback"
          @click="handleSubmitFeedback"
        >
          Submit
        </base-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import {
  BaseInput,
  BaseButton,
  BaseText,
  StatusBadge
} from '@loophq/ui';
import assistant from '../../../util/api/assistant';
import AssistantTool from '../../assistantTools';

const store = useStore();

const props = defineProps({
  isCollapsed: {
    type: Boolean,
    required: true
  },
  context: {
    type: String,
    required: false,
  }
});

const emit = defineEmits(['update:width', 'collapse']);

// State
const width = ref(parseInt(localStorage.getItem('assistantSidebarWidth')) || 400);
const minWidth = 200;
const maxWidth = 600;
const inputText = ref('');
const sessionId = ref(null);
const messages = ref([]);
const formattedMessages = ref([]);
const isThinking = ref(false);
const isRatingShown = ref(false);
const isAdditionalFeedbackShown = ref(false);
const isSubmittingFeedback = ref(false);
const additionalFeedback = ref(null);
const chatMessageWrapper = ref(null);

// Watchers
watch([messages, isThinking], async () => {
  await nextTick();
  setTimeout(scrollToBottom, 100);
});

watch(() => props.isCollapsed, (newValue) => {
  localStorage.setItem('assistantSidebarCollapsed', newValue);
});

// Methods
const handleClose = () => {
  if (sessionId.value) {
    promptForFeedback();
  } else {
    emit('collapse', true);
  }
};

const promptForFeedback = () => {
  if (sessionId.value) {
    isRatingShown.value = true;
  } else {
    closeAssistant();
  }
};

const handleSubmitFeedback = async () => {
  isSubmittingFeedback.value = true;

  try {
    await assistant.submitFeedback({
      session_id: sessionId.value,
      rating: -1,
      feedback: additionalFeedback.value,
    });

    closeAssistant();
  } catch (e) {
    console.error(e);
    store.commit('setToast', {
      message: 'An error occurred. Please try again.',
      type: 'error',
    });
  }

  isSubmittingFeedback.value = false;
};

const handleThumbsUp = async () => {
  isAdditionalFeedbackShown.value = false;
  isSubmittingFeedback.value = true;

  try {
    await assistant.submitFeedback({
      session_id: sessionId.value,
      rating: 1,
    });

    closeAssistant();
  } catch (e) {
    console.error(e);
    store.commit('setToast', {
      message: 'An error occurred. Please try again.',
      type: 'error',
    });
  }

  isSubmittingFeedback.value = false;
};

const handleThumbsDown = () => {
  isAdditionalFeedbackShown.value = true;
};

const closeAssistant = () => {
  sessionId.value = null;
  inputText.value = '';
  messages.value = [];
  formattedMessages.value = [];
  isThinking.value = false;
  isRatingShown.value = false;
  isAdditionalFeedbackShown.value = false;
  isSubmittingFeedback.value = false;
  additionalFeedback.value = null;
  emit('collapse', true);
};

const sendMessage = async () => {
  if (!inputText.value) return;

  const data = {
    session_id: sessionId.value,
    messages: [...messages.value],
    message: inputText.value,
  };

  if (props.context) {
    data.context = props.context;
  }

  inputText.value = '';

  messages.value.push({
    role: 'user',
    content: data.message,
  });

  formattedMessages.value.push({
    role: 'user',
    content: data.message,
  });

  isThinking.value = true;

  try {
    const response = await assistant.chat(data);

    sessionId.value = response.session_id;
    messages.value = response.messages;
    formattedMessages.value = response.formatted_messages;
  } catch (e) {
    console.error(e);
    store.commit('setToast', {
      message: 'An error occurred. Please try again.',
      type: 'error',
    });
  }

  isThinking.value = false;
};

const handleToolMessage = (toolMessage) => {
  // Add the message from the tool to the chat
  const messageContent = toolMessage.content;

  const data = {
    session_id: sessionId.value,
    messages: [...messages.value],
    message: messageContent,
  };

  if (props.context) {
    data.context = props.context;
  }

  messages.value.push({
    role: 'user',
    content: messageContent,
  });

  formattedMessages.value.push({
    role: 'user',
    content: messageContent,
  });

  isThinking.value = true;

  assistant.chat(data)
    .then(response => {
      sessionId.value = response.session_id;
      messages.value = response.messages;
      formattedMessages.value = response.formatted_messages;
    })
    .catch(e => {
      console.error(e);
      store.commit('setToast', {
        message: 'An error occurred. Please try again.',
        type: 'error',
      });
    })
    .finally(() => {
      isThinking.value = false;
    });
};

const startResize = (event) => {
  event.preventDefault();

  const startX = event.clientX;
  const startWidth = width.value;

  const handleMouseMove = (e) => {
    const delta = startX - e.clientX;
    const newWidth = Math.min(Math.max(startWidth + delta, minWidth), maxWidth);
    width.value = newWidth;
    localStorage.setItem('assistantSidebarWidth', newWidth);
    emit('update:width', newWidth);
    document.documentElement.style.setProperty('--assistant-sidebar-width', newWidth + 'px');
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', handleMouseUp);
};

const chatBubbleClass = (message) => ({
  'chat-message--user': message.role === 'user',
  'chat-message--assistant': message.role === 'assistant',
});

const scrollToBottom = () => {
  if (chatMessageWrapper.value) {
    chatMessageWrapper.value.scrollTop = chatMessageWrapper.value.scrollHeight;
  }
};

onMounted(() => {
  document.documentElement.style.setProperty('--assistant-sidebar-width', width.value + 'px');
});
</script>

<style lang="scss" scoped>
.assistant-sidebar {
  position: fixed;
  top: 72px; // Match header height
  right: 0;
  bottom: 0;
  width: var(--assistant-sidebar-width, 300px);
  border-left: 1px solid var(--color-grey-200);
  background: var(--color-grey-100);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, opacity 0.2s ease, visibility 0.3s ease;
  z-index: 100;
  visibility: visible;
  opacity: 1;

  @media (width <= 768px) {
    width: 100% !important;

    --assistant-sidebar-width: 100% !important;
  }

  &.is-collapsed {
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: var(--spacing-16);
    z-index: 1;
  }

  &__resize-handle {
    position: absolute;
    left: -2px;
    top: 0;
    bottom: 0;
    width: 4px;
    cursor: col-resize;

    @media (width <= 768px) {
      display: none;
    }
  }

  &__header {
    padding: var(--spacing-300);
    padding-bottom: 0;
    flex-shrink: 0;
    text-align: center;

    &-content {
      display: flex;
      align-items: center;
      gap: var(--spacing-8);
      justify-content: center;
    }
  }

  &__content {
    flex: 1;
    overflow: hidden;
    padding: var(--spacing-300);
    padding-bottom: var(--spacing-200);
    display: flex;
    flex-direction: column;
  }

  &__content-inner {
    background: white;
    border-radius: 8px;
    padding: var(--spacing-300);
    flex: 1;
    overflow-y: auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 5%);
  }

  &__footer {
    padding: var(--spacing-300);
    padding-top: var(--spacing-200);
  }

  &__input {
    width: 100%;
  }
}

.chat-messages {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.chat-message {
  align-items: center;
  display: flex;
  gap: var(--spacing-12);
  position: relative;

  &__bubble {
    background-color: var(--color-neutral-50);
    border-radius: 16px;
    color: var(--color-neutral-900);
    display: inline-block;
    max-width: 90%;
    padding: var(--spacing-16);

    &.--success {
      background-color: var(--color-highland-200);
    }

    &.--error {
      background-color: var(--color-mahogany-200);
    }
  }
}

.chat-message--user {
  display: flex;
  align-items: flex-end;

  .chat-message__bubble {
    background-color: var(--color-lochmara-50);
    margin-left: auto;
  }
}

@keyframes thinkingSpinner {
  to {
    transform: rotate(360deg);
  }
}

.thinking-spinner {
  width: 19px;
  height: 19px;
  position: relative;

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--color-neutral-100);
    border-top-color: #000;
    animation: thinkingSpinner 0.6s linear infinite;
  }
}

.assistant-rating {
  align-items: center;
  background-color: rgba(255, 255, 255, 95%);
  border-left: 1px solid var(--color-grey-200);
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  padding: var(--spacing-32);
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;

  &__title {
    margin-bottom: var(--spacing-24) !important;
  }

  &__ratings {
    display: flex;
    flex-flow: row wrap;
    gap: var(--spacing-16);
  }

  &__rating {
    width: 200px;
  }

  &__feedback {
    margin-top: var(--spacing-32);
    width: 100%;
  }

  &__feedback-input {
    margin-bottom: var(--spacing-8);
    width: 100%;
  }

  &__feedback-cta {
    width: 100%;
  }
}

.mb-0 {
  margin-bottom: 0;
}
</style>

<style lang="scss">
.chat-message__bubble {
  p {
    margin: var(--spacing-16) 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin: 1rem 0;
    padding: 0;

    li {
      list-style: inside;
      margin-left: 1rem;
      margin-bottom: 0.2rem;

      p {
        margin: 0;
      }

      ul,
      ol {
        margin: 0.5rem 0;
      }
    }
  }
}
</style>
