export interface Card {
    brand: string;
    expiration_month: string;
    expiration_year: string;
    last_four_digits: string;
  }

export interface PaymentSource {
  card: Card,
  id: string;
  payment_method_type: string;
  priority: string;
  threshold: string;
  recharge_amount: string;
  error: boolean;
}

export function PaymentMethods(sbl: any) {
  const sources: PaymentSource[] = [];

  if (sbl?.primaryPaymentMethod) {
    sources.push({
      card: {
        brand: sbl.primaryPaymentMethod.brand,
        expiration_month: sbl.primaryPaymentMethod.expirationMonth.toString(),
        expiration_year: sbl.primaryPaymentMethod.expirationYear.toString(),
        last_four_digits: sbl.primaryPaymentMethod.last4
      },
      id: '0',
      payment_method_type: 'card',
      priority: 'primary',
      threshold: sbl.rechargeThreshold,
      recharge_amount: sbl.rechargeAmount,
      error: false
    });
  }

  if (sbl?.secondaryPaymentMethod) {
    sources.push({
      card: {
        brand: sbl.secondaryPaymentMethod.brand,
        expiration_month: sbl.secondaryPaymentMethod.expirationMonth.toString(),
        expiration_year: sbl.secondaryPaymentMethod.expirationYear.toString(),
        last_four_digits: sbl.secondaryPaymentMethod.last4
      },
      id: sources.length.toString(),
      payment_method_type: 'card',
      priority: 'secondary',
      threshold: sbl.rechargeThreshold,
      recharge_amount: sbl.rechargeAmount,
      error: false
    });
  }

  return sources;
}
export interface RechargeSettings {
  threshold: string;
  amount: string;
}