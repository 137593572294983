export const trackingEvents = {
  WORKFLOW_CREATED: 'workflowCreated',
  WORKFLOW_TEMPLATE_SELECTED: 'workflowTemplateSelected',
  WORKFLOW_BACK: 'workflowBack',
  WORKFLOW_DRAFT_SAVED: 'workflowDraftSaved',
  WORKFLOW_PUBLISHED: 'workflowPublished',
  WORKFLOW_EDITED: 'workflowEdited',
  WORKFLOW_DEACTIVATED: 'workflowDeactivated',
  WORKFLOW_DELETED: 'workflowDeleted',
  WORKFLOW_ASSISTANT_OPENED: 'workflowAssistantOpened',
  NAV_ITEM_CLICKED: 'Nav Item Clicked',
  SAVE_BAR_SAVE_CLICKED: 'Save Bar Save Clicked',
  PAGE_VIEW: 'pageView',
  USER_LOGIN: 'User Login',
  GLOBAL_SEARCH_RESULT_CLICKED: 'globalSearchResultClicked',
  GLOBAL_SEARCH_PERFORMED: 'globalSearchPerformed',
};

export const trackingEventProperties = {
  ADMIN_NAV_UPDATED_V1: 'nvd-v1',
  WORKFLOW_ASSISTANT_BANNER: 'banner',
  WORKFLOW_ASSISTANT_ICON: 'icon',
};
