import env from '@/env';

// Default dependencies
const defaultDependencies = {
  env,
  analytics: typeof window !== 'undefined' ? window.analytics : undefined
};

/**
 * Creates segment helper functions with the provided dependencies
 * @param {Object} dependencies - Dependencies for the segment helper
 * @param {Function} dependencies.env - Environment variable getter function
 * @param {Object} dependencies.analytics - Analytics object
 * @returns {Object} Segment helper functions
 */
export const createSegmentHelper = (dependencies = {}) => {
  const deps = { ...defaultDependencies, ...dependencies };
  
  /**
   * Initialize Segment analytics
   */
  const initSegment = () => {
    if (!deps.env('VITE_SEGMENT_WRITE_KEY')) { 
      return false;
    }

    // Check if already initialized
    if (typeof window !== 'undefined' && window.analytics && window.analytics.initialized) {
      return true;
    }
    
    try {
      !function() {
        var analytics=window.analytics=window.analytics||[];if (!analytics.initialize) if (analytics.invoked)window.console&&console.error&&console.error('Segment snippet included twice.');else {
          analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','screen','once','off','on','addSourceMiddleware','addIntegrationMiddleware','setAnonymousId','addDestinationMiddleware','register'];analytics.factory=function(e) {return function() {if (window.analytics.initialized) return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);if (['track','screen','alias','group','page','identify'].indexOf(e)>-1) {var c=document.querySelector('link[rel=\'canonical\']');i.push({ __t: 'bpc',c: c&&c.getAttribute('href')||void 0,p: location.pathname,u: location.href,s: location.search,t: document.title,r: document.referrer });}i.unshift(e);analytics.push(i);return analytics;};};for (var i=0;i<analytics.methods.length;i++) {var key=analytics.methods[i];analytics[key]=analytics.factory(key);}analytics.load=function(key,i) {var t=document.createElement('script');t.type='text/javascript';t.async=!0;t.src='https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';var n=document.getElementsByTagName('script')[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i;};analytics._writeKey=`${deps.env('VITE_SEGMENT_WRITE_KEY')}`;analytics.SNIPPET_VERSION='5.2.0';
          analytics.load(deps.env('VITE_SEGMENT_WRITE_KEY'));
        }
      }();
      
      // Update the analytics reference in deps
      deps.analytics = window.analytics;
      
      return true;
    } catch (error) {
      console.error('Error initializing Segment');
      return false;
    }
  };

  /**
   * Link users and their actions
   * When adding properties, reach out to the security team to understand the impact before adding PII
   * @param {object} user user details
   * @param {object} [shop] shop details
   */
  const identify = (user, shop) => {
    if (!deps.env('VITE_SEGMENT_WRITE_KEY') || !deps.analytics) { 
      return;
    }

    const userProps = {};

    if (shop) {
      deps.analytics.group(shop.id, {
        userId: user?.id,
        name: shop.name,
        legacyPlanId: shop.plan_id,
        legacyPlanName: shop.plan?.name,
        country_code: shop.country,
      });

      userProps.company = shop.name;
      userProps.groupId = shop.id;
    }

    if (user) {
      deps.analytics.identify(user.id, { 
        name: user.name || `User ${user.id}`,
        title: user.job_title,
        accountOwner: user.roles && user.roles.find(role => role === 'account owner') ? 'yes' : 'no',
        ...userProps,
      });
    }
  };

  /**
   * Record actions a user performs
   * @param {string} event 
   * @param {object} [properties] extra info about the event
   */
  const track = (event, properties = {}) => {
    if (!deps.env('VITE_SEGMENT_WRITE_KEY') || !deps.analytics) { 
      return;
    }

    try {
      deps.analytics.track(event, {
        product: 'admin',
        ...properties
      });
    } catch (error) {
      // Keep only essential error logging for production
      console.error('Error tracking event');
    }
  };

  return {
    initSegment,
    identify,
    track
  };
};

// Create default instance with default dependencies for backward compatibility
const defaultInstance = createSegmentHelper();

// Export the factory and the default functions for backward compatibility
export const { initSegment, identify, track } = defaultInstance;
